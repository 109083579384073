@-webkit-keyframes shake {
  59% {
    margin-left: 0;
  }
  60%, 80% {
    margin-left: 2px;
  }
  70%, 90% {
    margin-left: -2px;
  }
}
@keyframes shake {
  59% {
    margin-left: 0;
  }
  60%, 80% {
    margin-left: 2px;
  }
  70%, 90% {
    margin-left: -2px;
  }
}
.black {
  background-color: #000 !important;
  border-color: #000 !important;
}
.black--text {
  color: #000 !important;
}
.black--text input, .black--text textarea {
  caret-color: #000 !important;
}
.white {
  background-color: #fff !important;
  border-color: #fff !important;
}
.white--text {
  color: #fff !important;
}
.white--text input, .white--text textarea {
  caret-color: #fff !important;
}
.transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}
.transparent--text {
  color: transparent !important;
}
.transparent--text input, .transparent--text textarea {
  caret-color: transparent !important;
}
.red {
  background-color: #f44336 !important;
  border-color: #f44336 !important;
}
.red--text {
  color: #f44336 !important;
}
.red--text input, .red--text textarea {
  caret-color: #f44336 !important;
}
.red.lighten-5 {
  background-color: #ffebee !important;
  border-color: #ffebee !important;
}
.red--text.text--lighten-5 {
  color: #ffebee !important;
}
.red--text.text--lighten-5 input, .red--text.text--lighten-5 textarea {
  caret-color: #ffebee !important;
}
.red.lighten-4 {
  background-color: #ffcdd2 !important;
  border-color: #ffcdd2 !important;
}
.red--text.text--lighten-4 {
  color: #ffcdd2 !important;
}
.red--text.text--lighten-4 input, .red--text.text--lighten-4 textarea {
  caret-color: #ffcdd2 !important;
}
.red.lighten-3 {
  background-color: #ef9a9a !important;
  border-color: #ef9a9a !important;
}
.red--text.text--lighten-3 {
  color: #ef9a9a !important;
}
.red--text.text--lighten-3 input, .red--text.text--lighten-3 textarea {
  caret-color: #ef9a9a !important;
}
.red.lighten-2 {
  background-color: #e57373 !important;
  border-color: #e57373 !important;
}
.red--text.text--lighten-2 {
  color: #e57373 !important;
}
.red--text.text--lighten-2 input, .red--text.text--lighten-2 textarea {
  caret-color: #e57373 !important;
}
.red.lighten-1 {
  background-color: #ef5350 !important;
  border-color: #ef5350 !important;
}
.red--text.text--lighten-1 {
  color: #ef5350 !important;
}
.red--text.text--lighten-1 input, .red--text.text--lighten-1 textarea {
  caret-color: #ef5350 !important;
}
.red.darken-1 {
  background-color: #e53935 !important;
  border-color: #e53935 !important;
}
.red--text.text--darken-1 {
  color: #e53935 !important;
}
.red--text.text--darken-1 input, .red--text.text--darken-1 textarea {
  caret-color: #e53935 !important;
}
.red.darken-2 {
  background-color: #d32f2f !important;
  border-color: #d32f2f !important;
}
.red--text.text--darken-2 {
  color: #d32f2f !important;
}
.red--text.text--darken-2 input, .red--text.text--darken-2 textarea {
  caret-color: #d32f2f !important;
}
.red.darken-3 {
  background-color: #c62828 !important;
  border-color: #c62828 !important;
}
.red--text.text--darken-3 {
  color: #c62828 !important;
}
.red--text.text--darken-3 input, .red--text.text--darken-3 textarea {
  caret-color: #c62828 !important;
}
.red.darken-4 {
  background-color: #b71c1c !important;
  border-color: #b71c1c !important;
}
.red--text.text--darken-4 {
  color: #b71c1c !important;
}
.red--text.text--darken-4 input, .red--text.text--darken-4 textarea {
  caret-color: #b71c1c !important;
}
.red.accent-1 {
  background-color: #ff8a80 !important;
  border-color: #ff8a80 !important;
}
.red--text.text--accent-1 {
  color: #ff8a80 !important;
}
.red--text.text--accent-1 input, .red--text.text--accent-1 textarea {
  caret-color: #ff8a80 !important;
}
.red.accent-2 {
  background-color: #ff5252 !important;
  border-color: #ff5252 !important;
}
.red--text.text--accent-2 {
  color: #ff5252 !important;
}
.red--text.text--accent-2 input, .red--text.text--accent-2 textarea {
  caret-color: #ff5252 !important;
}
.red.accent-3 {
  background-color: #ff1744 !important;
  border-color: #ff1744 !important;
}
.red--text.text--accent-3 {
  color: #ff1744 !important;
}
.red--text.text--accent-3 input, .red--text.text--accent-3 textarea {
  caret-color: #ff1744 !important;
}
.red.accent-4 {
  background-color: #d50000 !important;
  border-color: #d50000 !important;
}
.red--text.text--accent-4 {
  color: #d50000 !important;
}
.red--text.text--accent-4 input, .red--text.text--accent-4 textarea {
  caret-color: #d50000 !important;
}
.blue {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}
.blue--text {
  color: #2196f3 !important;
}
.blue--text input, .blue--text textarea {
  caret-color: #2196f3 !important;
}
.blue.lighten-5 {
  background-color: #e3f2fd !important;
  border-color: #e3f2fd !important;
}
.blue--text.text--lighten-5 {
  color: #e3f2fd !important;
}
.blue--text.text--lighten-5 input, .blue--text.text--lighten-5 textarea {
  caret-color: #e3f2fd !important;
}
.blue.lighten-4 {
  background-color: #bbdefb !important;
  border-color: #bbdefb !important;
}
.blue--text.text--lighten-4 {
  color: #bbdefb !important;
}
.blue--text.text--lighten-4 input, .blue--text.text--lighten-4 textarea {
  caret-color: #bbdefb !important;
}
.blue.lighten-3 {
  background-color: #90caf9 !important;
  border-color: #90caf9 !important;
}
.blue--text.text--lighten-3 {
  color: #90caf9 !important;
}
.blue--text.text--lighten-3 input, .blue--text.text--lighten-3 textarea {
  caret-color: #90caf9 !important;
}
.blue.lighten-2 {
  background-color: #64b5f6 !important;
  border-color: #64b5f6 !important;
}
.blue--text.text--lighten-2 {
  color: #64b5f6 !important;
}
.blue--text.text--lighten-2 input, .blue--text.text--lighten-2 textarea {
  caret-color: #64b5f6 !important;
}
.blue.lighten-1 {
  background-color: #42a5f5 !important;
  border-color: #42a5f5 !important;
}
.blue--text.text--lighten-1 {
  color: #42a5f5 !important;
}
.blue--text.text--lighten-1 input, .blue--text.text--lighten-1 textarea {
  caret-color: #42a5f5 !important;
}
.blue.darken-1 {
  background-color: #1e88e5 !important;
  border-color: #1e88e5 !important;
}
.blue--text.text--darken-1 {
  color: #1e88e5 !important;
}
.blue--text.text--darken-1 input, .blue--text.text--darken-1 textarea {
  caret-color: #1e88e5 !important;
}
.blue.darken-2 {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
}
.blue--text.text--darken-2 {
  color: #1976d2 !important;
}
.blue--text.text--darken-2 input, .blue--text.text--darken-2 textarea {
  caret-color: #1976d2 !important;
}
.blue.darken-3 {
  background-color: #1565c0 !important;
  border-color: #1565c0 !important;
}
.blue--text.text--darken-3 {
  color: #1565c0 !important;
}
.blue--text.text--darken-3 input, .blue--text.text--darken-3 textarea {
  caret-color: #1565c0 !important;
}
.blue.darken-4 {
  background-color: #0d47a1 !important;
  border-color: #0d47a1 !important;
}
.blue--text.text--darken-4 {
  color: #0d47a1 !important;
}
.blue--text.text--darken-4 input, .blue--text.text--darken-4 textarea {
  caret-color: #0d47a1 !important;
}
.blue.accent-1 {
  background-color: #82b1ff !important;
  border-color: #82b1ff !important;
}
.blue--text.text--accent-1 {
  color: #82b1ff !important;
}
.blue--text.text--accent-1 input, .blue--text.text--accent-1 textarea {
  caret-color: #82b1ff !important;
}
.blue.accent-2 {
  background-color: #448aff !important;
  border-color: #448aff !important;
}
.blue--text.text--accent-2 {
  color: #448aff !important;
}
.blue--text.text--accent-2 input, .blue--text.text--accent-2 textarea {
  caret-color: #448aff !important;
}
.blue.accent-3 {
  background-color: #2979ff !important;
  border-color: #2979ff !important;
}
.blue--text.text--accent-3 {
  color: #2979ff !important;
}
.blue--text.text--accent-3 input, .blue--text.text--accent-3 textarea {
  caret-color: #2979ff !important;
}
.blue.accent-4 {
  background-color: #2962ff !important;
  border-color: #2962ff !important;
}
.blue--text.text--accent-4 {
  color: #2962ff !important;
}
.blue--text.text--accent-4 input, .blue--text.text--accent-4 textarea {
  caret-color: #2962ff !important;
}
.light-blue {
  background-color: #03a9f4 !important;
  border-color: #03a9f4 !important;
}
.light-blue--text {
  color: #03a9f4 !important;
}
.light-blue--text input, .light-blue--text textarea {
  caret-color: #03a9f4 !important;
}
.light-blue.lighten-5 {
  background-color: #e1f5fe !important;
  border-color: #e1f5fe !important;
}
.light-blue--text.text--lighten-5 {
  color: #e1f5fe !important;
}
.light-blue--text.text--lighten-5 input, .light-blue--text.text--lighten-5 textarea {
  caret-color: #e1f5fe !important;
}
.light-blue.lighten-4 {
  background-color: #b3e5fc !important;
  border-color: #b3e5fc !important;
}
.light-blue--text.text--lighten-4 {
  color: #b3e5fc !important;
}
.light-blue--text.text--lighten-4 input, .light-blue--text.text--lighten-4 textarea {
  caret-color: #b3e5fc !important;
}
.light-blue.lighten-3 {
  background-color: #81d4fa !important;
  border-color: #81d4fa !important;
}
.light-blue--text.text--lighten-3 {
  color: #81d4fa !important;
}
.light-blue--text.text--lighten-3 input, .light-blue--text.text--lighten-3 textarea {
  caret-color: #81d4fa !important;
}
.light-blue.lighten-2 {
  background-color: #4fc3f7 !important;
  border-color: #4fc3f7 !important;
}
.light-blue--text.text--lighten-2 {
  color: #4fc3f7 !important;
}
.light-blue--text.text--lighten-2 input, .light-blue--text.text--lighten-2 textarea {
  caret-color: #4fc3f7 !important;
}
.light-blue.lighten-1 {
  background-color: #29b6f6 !important;
  border-color: #29b6f6 !important;
}
.light-blue--text.text--lighten-1 {
  color: #29b6f6 !important;
}
.light-blue--text.text--lighten-1 input, .light-blue--text.text--lighten-1 textarea {
  caret-color: #29b6f6 !important;
}
.light-blue.darken-1 {
  background-color: #039be5 !important;
  border-color: #039be5 !important;
}
.light-blue--text.text--darken-1 {
  color: #039be5 !important;
}
.light-blue--text.text--darken-1 input, .light-blue--text.text--darken-1 textarea {
  caret-color: #039be5 !important;
}
.light-blue.darken-2 {
  background-color: #0288d1 !important;
  border-color: #0288d1 !important;
}
.light-blue--text.text--darken-2 {
  color: #0288d1 !important;
}
.light-blue--text.text--darken-2 input, .light-blue--text.text--darken-2 textarea {
  caret-color: #0288d1 !important;
}
.light-blue.darken-3 {
  background-color: #0277bd !important;
  border-color: #0277bd !important;
}
.light-blue--text.text--darken-3 {
  color: #0277bd !important;
}
.light-blue--text.text--darken-3 input, .light-blue--text.text--darken-3 textarea {
  caret-color: #0277bd !important;
}
.light-blue.darken-4 {
  background-color: #01579b !important;
  border-color: #01579b !important;
}
.light-blue--text.text--darken-4 {
  color: #01579b !important;
}
.light-blue--text.text--darken-4 input, .light-blue--text.text--darken-4 textarea {
  caret-color: #01579b !important;
}
.light-blue.accent-1 {
  background-color: #80d8ff !important;
  border-color: #80d8ff !important;
}
.light-blue--text.text--accent-1 {
  color: #80d8ff !important;
}
.light-blue--text.text--accent-1 input, .light-blue--text.text--accent-1 textarea {
  caret-color: #80d8ff !important;
}
.light-blue.accent-2 {
  background-color: #40c4ff !important;
  border-color: #40c4ff !important;
}
.light-blue--text.text--accent-2 {
  color: #40c4ff !important;
}
.light-blue--text.text--accent-2 input, .light-blue--text.text--accent-2 textarea {
  caret-color: #40c4ff !important;
}
.light-blue.accent-3 {
  background-color: #00b0ff !important;
  border-color: #00b0ff !important;
}
.light-blue--text.text--accent-3 {
  color: #00b0ff !important;
}
.light-blue--text.text--accent-3 input, .light-blue--text.text--accent-3 textarea {
  caret-color: #00b0ff !important;
}
.light-blue.accent-4 {
  background-color: #0091ea !important;
  border-color: #0091ea !important;
}
.light-blue--text.text--accent-4 {
  color: #0091ea !important;
}
.light-blue--text.text--accent-4 input, .light-blue--text.text--accent-4 textarea {
  caret-color: #0091ea !important;
}
.cyan {
  background-color: #00bcd4 !important;
  border-color: #00bcd4 !important;
}
.cyan--text {
  color: #00bcd4 !important;
}
.cyan--text input, .cyan--text textarea {
  caret-color: #00bcd4 !important;
}
.cyan.lighten-5 {
  background-color: #e0f7fa !important;
  border-color: #e0f7fa !important;
}
.cyan--text.text--lighten-5 {
  color: #e0f7fa !important;
}
.cyan--text.text--lighten-5 input, .cyan--text.text--lighten-5 textarea {
  caret-color: #e0f7fa !important;
}
.cyan.lighten-4 {
  background-color: #b2ebf2 !important;
  border-color: #b2ebf2 !important;
}
.cyan--text.text--lighten-4 {
  color: #b2ebf2 !important;
}
.cyan--text.text--lighten-4 input, .cyan--text.text--lighten-4 textarea {
  caret-color: #b2ebf2 !important;
}
.cyan.lighten-3 {
  background-color: #80deea !important;
  border-color: #80deea !important;
}
.cyan--text.text--lighten-3 {
  color: #80deea !important;
}
.cyan--text.text--lighten-3 input, .cyan--text.text--lighten-3 textarea {
  caret-color: #80deea !important;
}
.cyan.lighten-2 {
  background-color: #4dd0e1 !important;
  border-color: #4dd0e1 !important;
}
.cyan--text.text--lighten-2 {
  color: #4dd0e1 !important;
}
.cyan--text.text--lighten-2 input, .cyan--text.text--lighten-2 textarea {
  caret-color: #4dd0e1 !important;
}
.cyan.lighten-1 {
  background-color: #26c6da !important;
  border-color: #26c6da !important;
}
.cyan--text.text--lighten-1 {
  color: #26c6da !important;
}
.cyan--text.text--lighten-1 input, .cyan--text.text--lighten-1 textarea {
  caret-color: #26c6da !important;
}
.cyan.darken-1 {
  background-color: #00acc1 !important;
  border-color: #00acc1 !important;
}
.cyan--text.text--darken-1 {
  color: #00acc1 !important;
}
.cyan--text.text--darken-1 input, .cyan--text.text--darken-1 textarea {
  caret-color: #00acc1 !important;
}
.cyan.darken-2 {
  background-color: #0097a7 !important;
  border-color: #0097a7 !important;
}
.cyan--text.text--darken-2 {
  color: #0097a7 !important;
}
.cyan--text.text--darken-2 input, .cyan--text.text--darken-2 textarea {
  caret-color: #0097a7 !important;
}
.cyan.darken-3 {
  background-color: #00838f !important;
  border-color: #00838f !important;
}
.cyan--text.text--darken-3 {
  color: #00838f !important;
}
.cyan--text.text--darken-3 input, .cyan--text.text--darken-3 textarea {
  caret-color: #00838f !important;
}
.cyan.darken-4 {
  background-color: #006064 !important;
  border-color: #006064 !important;
}
.cyan--text.text--darken-4 {
  color: #006064 !important;
}
.cyan--text.text--darken-4 input, .cyan--text.text--darken-4 textarea {
  caret-color: #006064 !important;
}
.cyan.accent-1 {
  background-color: #84ffff !important;
  border-color: #84ffff !important;
}
.cyan--text.text--accent-1 {
  color: #84ffff !important;
}
.cyan--text.text--accent-1 input, .cyan--text.text--accent-1 textarea {
  caret-color: #84ffff !important;
}
.cyan.accent-2 {
  background-color: #18ffff !important;
  border-color: #18ffff !important;
}
.cyan--text.text--accent-2 {
  color: #18ffff !important;
}
.cyan--text.text--accent-2 input, .cyan--text.text--accent-2 textarea {
  caret-color: #18ffff !important;
}
.cyan.accent-3 {
  background-color: #00e5ff !important;
  border-color: #00e5ff !important;
}
.cyan--text.text--accent-3 {
  color: #00e5ff !important;
}
.cyan--text.text--accent-3 input, .cyan--text.text--accent-3 textarea {
  caret-color: #00e5ff !important;
}
.cyan.accent-4 {
  background-color: #00b8d4 !important;
  border-color: #00b8d4 !important;
}
.cyan--text.text--accent-4 {
  color: #00b8d4 !important;
}
.cyan--text.text--accent-4 input, .cyan--text.text--accent-4 textarea {
  caret-color: #00b8d4 !important;
}
.green {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}
.green--text {
  color: #4caf50 !important;
}
.green--text input, .green--text textarea {
  caret-color: #4caf50 !important;
}
.green.lighten-5 {
  background-color: #e8f5e9 !important;
  border-color: #e8f5e9 !important;
}
.green--text.text--lighten-5 {
  color: #e8f5e9 !important;
}
.green--text.text--lighten-5 input, .green--text.text--lighten-5 textarea {
  caret-color: #e8f5e9 !important;
}
.green.lighten-4 {
  background-color: #c8e6c9 !important;
  border-color: #c8e6c9 !important;
}
.green--text.text--lighten-4 {
  color: #c8e6c9 !important;
}
.green--text.text--lighten-4 input, .green--text.text--lighten-4 textarea {
  caret-color: #c8e6c9 !important;
}
.green.lighten-3 {
  background-color: #a5d6a7 !important;
  border-color: #a5d6a7 !important;
}
.green--text.text--lighten-3 {
  color: #a5d6a7 !important;
}
.green--text.text--lighten-3 input, .green--text.text--lighten-3 textarea {
  caret-color: #a5d6a7 !important;
}
.green.lighten-2 {
  background-color: #81c784 !important;
  border-color: #81c784 !important;
}
.green--text.text--lighten-2 {
  color: #81c784 !important;
}
.green--text.text--lighten-2 input, .green--text.text--lighten-2 textarea {
  caret-color: #81c784 !important;
}
.green.lighten-1 {
  background-color: #66bb6a !important;
  border-color: #66bb6a !important;
}
.green--text.text--lighten-1 {
  color: #66bb6a !important;
}
.green--text.text--lighten-1 input, .green--text.text--lighten-1 textarea {
  caret-color: #66bb6a !important;
}
.green.darken-1 {
  background-color: #43a047 !important;
  border-color: #43a047 !important;
}
.green--text.text--darken-1 {
  color: #43a047 !important;
}
.green--text.text--darken-1 input, .green--text.text--darken-1 textarea {
  caret-color: #43a047 !important;
}
.green.darken-2 {
  background-color: #388e3c !important;
  border-color: #388e3c !important;
}
.green--text.text--darken-2 {
  color: #388e3c !important;
}
.green--text.text--darken-2 input, .green--text.text--darken-2 textarea {
  caret-color: #388e3c !important;
}
.green.darken-3 {
  background-color: #2e7d32 !important;
  border-color: #2e7d32 !important;
}
.green--text.text--darken-3 {
  color: #2e7d32 !important;
}
.green--text.text--darken-3 input, .green--text.text--darken-3 textarea {
  caret-color: #2e7d32 !important;
}
.green.darken-4 {
  background-color: #1b5e20 !important;
  border-color: #1b5e20 !important;
}
.green--text.text--darken-4 {
  color: #1b5e20 !important;
}
.green--text.text--darken-4 input, .green--text.text--darken-4 textarea {
  caret-color: #1b5e20 !important;
}
.green.accent-1 {
  background-color: #b9f6ca !important;
  border-color: #b9f6ca !important;
}
.green--text.text--accent-1 {
  color: #b9f6ca !important;
}
.green--text.text--accent-1 input, .green--text.text--accent-1 textarea {
  caret-color: #b9f6ca !important;
}
.green.accent-2 {
  background-color: #69f0ae !important;
  border-color: #69f0ae !important;
}
.green--text.text--accent-2 {
  color: #69f0ae !important;
}
.green--text.text--accent-2 input, .green--text.text--accent-2 textarea {
  caret-color: #69f0ae !important;
}
.green.accent-3 {
  background-color: #00e676 !important;
  border-color: #00e676 !important;
}
.green--text.text--accent-3 {
  color: #00e676 !important;
}
.green--text.text--accent-3 input, .green--text.text--accent-3 textarea {
  caret-color: #00e676 !important;
}
.green.accent-4 {
  background-color: #00c853 !important;
  border-color: #00c853 !important;
}
.green--text.text--accent-4 {
  color: #00c853 !important;
}
.green--text.text--accent-4 input, .green--text.text--accent-4 textarea {
  caret-color: #00c853 !important;
}
.blue-grey {
  background-color: #607d8b !important;
  border-color: #607d8b !important;
}
.blue-grey--text {
  color: #607d8b !important;
}
.blue-grey--text input, .blue-grey--text textarea {
  caret-color: #607d8b !important;
}
.blue-grey.lighten-5 {
  background-color: #eceff1 !important;
  border-color: #eceff1 !important;
}
.blue-grey--text.text--lighten-5 {
  color: #eceff1 !important;
}
.blue-grey--text.text--lighten-5 input, .blue-grey--text.text--lighten-5 textarea {
  caret-color: #eceff1 !important;
}
.blue-grey.lighten-4 {
  background-color: #cfd8dc !important;
  border-color: #cfd8dc !important;
}
.blue-grey--text.text--lighten-4 {
  color: #cfd8dc !important;
}
.blue-grey--text.text--lighten-4 input, .blue-grey--text.text--lighten-4 textarea {
  caret-color: #cfd8dc !important;
}
.blue-grey.lighten-3 {
  background-color: #b0bec5 !important;
  border-color: #b0bec5 !important;
}
.blue-grey--text.text--lighten-3 {
  color: #b0bec5 !important;
}
.blue-grey--text.text--lighten-3 input, .blue-grey--text.text--lighten-3 textarea {
  caret-color: #b0bec5 !important;
}
.blue-grey.lighten-2 {
  background-color: #90a4ae !important;
  border-color: #90a4ae !important;
}
.blue-grey--text.text--lighten-2 {
  color: #90a4ae !important;
}
.blue-grey--text.text--lighten-2 input, .blue-grey--text.text--lighten-2 textarea {
  caret-color: #90a4ae !important;
}
.blue-grey.lighten-1 {
  background-color: #78909c !important;
  border-color: #78909c !important;
}
.blue-grey--text.text--lighten-1 {
  color: #78909c !important;
}
.blue-grey--text.text--lighten-1 input, .blue-grey--text.text--lighten-1 textarea {
  caret-color: #78909c !important;
}
.blue-grey.darken-1 {
  background-color: #546e7a !important;
  border-color: #546e7a !important;
}
.blue-grey--text.text--darken-1 {
  color: #546e7a !important;
}
.blue-grey--text.text--darken-1 input, .blue-grey--text.text--darken-1 textarea {
  caret-color: #546e7a !important;
}
.blue-grey.darken-2 {
  background-color: #455a64 !important;
  border-color: #455a64 !important;
}
.blue-grey--text.text--darken-2 {
  color: #455a64 !important;
}
.blue-grey--text.text--darken-2 input, .blue-grey--text.text--darken-2 textarea {
  caret-color: #455a64 !important;
}
.blue-grey.darken-3 {
  background-color: #37474f !important;
  border-color: #37474f !important;
}
.blue-grey--text.text--darken-3 {
  color: #37474f !important;
}
.blue-grey--text.text--darken-3 input, .blue-grey--text.text--darken-3 textarea {
  caret-color: #37474f !important;
}
.blue-grey.darken-4 {
  background-color: #263238 !important;
  border-color: #263238 !important;
}
.blue-grey--text.text--darken-4 {
  color: #263238 !important;
}
.blue-grey--text.text--darken-4 input, .blue-grey--text.text--darken-4 textarea {
  caret-color: #263238 !important;
}
.grey {
  background-color: #9e9e9e !important;
  border-color: #9e9e9e !important;
}
.grey--text {
  color: #9e9e9e !important;
}
.grey--text input, .grey--text textarea {
  caret-color: #9e9e9e !important;
}
.grey.lighten-5 {
  background-color: #fafafa !important;
  border-color: #fafafa !important;
}
.grey--text.text--lighten-5 {
  color: #fafafa !important;
}
.grey--text.text--lighten-5 input, .grey--text.text--lighten-5 textarea {
  caret-color: #fafafa !important;
}
.grey.lighten-4 {
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
}
.grey--text.text--lighten-4 {
  color: #f5f5f5 !important;
}
.grey--text.text--lighten-4 input, .grey--text.text--lighten-4 textarea {
  caret-color: #f5f5f5 !important;
}
.grey.lighten-3 {
  background-color: #eee !important;
  border-color: #eee !important;
}
.grey--text.text--lighten-3 {
  color: #eee !important;
}
.grey--text.text--lighten-3 input, .grey--text.text--lighten-3 textarea {
  caret-color: #eee !important;
}
.grey.lighten-2 {
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
}
.grey--text.text--lighten-2 {
  color: #e0e0e0 !important;
}
.grey--text.text--lighten-2 input, .grey--text.text--lighten-2 textarea {
  caret-color: #e0e0e0 !important;
}
.grey.lighten-1 {
  background-color: #bdbdbd !important;
  border-color: #bdbdbd !important;
}
.grey--text.text--lighten-1 {
  color: #bdbdbd !important;
}
.grey--text.text--lighten-1 input, .grey--text.text--lighten-1 textarea {
  caret-color: #bdbdbd !important;
}
.grey.darken-1 {
  background-color: #757575 !important;
  border-color: #757575 !important;
}
.grey--text.text--darken-1 {
  color: #757575 !important;
}
.grey--text.text--darken-1 input, .grey--text.text--darken-1 textarea {
  caret-color: #757575 !important;
}
.grey.darken-2 {
  background-color: #616161 !important;
  border-color: #616161 !important;
}
.grey--text.text--darken-2 {
  color: #616161 !important;
}
.grey--text.text--darken-2 input, .grey--text.text--darken-2 textarea {
  caret-color: #616161 !important;
}
.grey.darken-3 {
  background-color: #424242 !important;
  border-color: #424242 !important;
}
.grey--text.text--darken-3 {
  color: #424242 !important;
}
.grey--text.text--darken-3 input, .grey--text.text--darken-3 textarea {
  caret-color: #424242 !important;
}
.grey.darken-4 {
  background-color: #212121 !important;
  border-color: #212121 !important;
}
.grey--text.text--darken-4 {
  color: #212121 !important;
}
.grey--text.text--darken-4 input, .grey--text.text--darken-4 textarea {
  caret-color: #212121 !important;
}
.shades.black {
  background-color: #000 !important;
  border-color: #000 !important;
}
.shades--text.text--black {
  color: #000 !important;
}
.shades--text.text--black input, .shades--text.text--black textarea {
  caret-color: #000 !important;
}
.shades.white {
  background-color: #fff !important;
  border-color: #fff !important;
}
.shades--text.text--white {
  color: #fff !important;
}
.shades--text.text--white input, .shades--text.text--white textarea {
  caret-color: #fff !important;
}
.shades.transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}
.shades--text.text--transparent {
  color: transparent !important;
}
.shades--text.text--transparent input, .shades--text.text--transparent textarea {
  caret-color: transparent !important;
}
.elevation-0 {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12) !important;
}
.elevation-1 {
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}
.elevation-2 {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;
}
.elevation-3 {
  -webkit-box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12) !important;
}
.elevation-4 {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important;
}
.elevation-5 {
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12) !important;
}
.elevation-6 {
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;
}
.elevation-7 {
  -webkit-box-shadow: 0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12) !important;
}
.elevation-8 {
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important;
}
.elevation-9 {
  -webkit-box-shadow: 0px 5px 6px -3px rgba(0,0,0,0.2), 0px 9px 12px 1px rgba(0,0,0,0.14), 0px 3px 16px 2px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 5px 6px -3px rgba(0,0,0,0.2), 0px 9px 12px 1px rgba(0,0,0,0.14), 0px 3px 16px 2px rgba(0,0,0,0.12) !important;
}
.elevation-10 {
  -webkit-box-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12) !important;
}
.elevation-11 {
  -webkit-box-shadow: 0px 6px 7px -4px rgba(0,0,0,0.2), 0px 11px 15px 1px rgba(0,0,0,0.14), 0px 4px 20px 3px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 6px 7px -4px rgba(0,0,0,0.2), 0px 11px 15px 1px rgba(0,0,0,0.14), 0px 4px 20px 3px rgba(0,0,0,0.12) !important;
}
.elevation-12 {
  -webkit-box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12) !important;
}
.elevation-13 {
  -webkit-box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 13px 19px 2px rgba(0,0,0,0.14), 0px 5px 24px 4px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 13px 19px 2px rgba(0,0,0,0.14), 0px 5px 24px 4px rgba(0,0,0,0.12) !important;
}
.elevation-14 {
  -webkit-box-shadow: 0px 7px 9px -4px rgba(0,0,0,0.2), 0px 14px 21px 2px rgba(0,0,0,0.14), 0px 5px 26px 4px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 7px 9px -4px rgba(0,0,0,0.2), 0px 14px 21px 2px rgba(0,0,0,0.14), 0px 5px 26px 4px rgba(0,0,0,0.12) !important;
}
.elevation-15 {
  -webkit-box-shadow: 0px 8px 9px -5px rgba(0,0,0,0.2), 0px 15px 22px 2px rgba(0,0,0,0.14), 0px 6px 28px 5px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 8px 9px -5px rgba(0,0,0,0.2), 0px 15px 22px 2px rgba(0,0,0,0.14), 0px 6px 28px 5px rgba(0,0,0,0.12) !important;
}
.elevation-16 {
  -webkit-box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12) !important;
}
.elevation-17 {
  -webkit-box-shadow: 0px 8px 11px -5px rgba(0,0,0,0.2), 0px 17px 26px 2px rgba(0,0,0,0.14), 0px 6px 32px 5px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 8px 11px -5px rgba(0,0,0,0.2), 0px 17px 26px 2px rgba(0,0,0,0.14), 0px 6px 32px 5px rgba(0,0,0,0.12) !important;
}
.elevation-18 {
  -webkit-box-shadow: 0px 9px 11px -5px rgba(0,0,0,0.2), 0px 18px 28px 2px rgba(0,0,0,0.14), 0px 7px 34px 6px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 9px 11px -5px rgba(0,0,0,0.2), 0px 18px 28px 2px rgba(0,0,0,0.14), 0px 7px 34px 6px rgba(0,0,0,0.12) !important;
}
.elevation-19 {
  -webkit-box-shadow: 0px 9px 12px -6px rgba(0,0,0,0.2), 0px 19px 29px 2px rgba(0,0,0,0.14), 0px 7px 36px 6px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 9px 12px -6px rgba(0,0,0,0.2), 0px 19px 29px 2px rgba(0,0,0,0.14), 0px 7px 36px 6px rgba(0,0,0,0.12) !important;
}
.elevation-20 {
  -webkit-box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2), 0px 20px 31px 3px rgba(0,0,0,0.14), 0px 8px 38px 7px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2), 0px 20px 31px 3px rgba(0,0,0,0.14), 0px 8px 38px 7px rgba(0,0,0,0.12) !important;
}
.elevation-21 {
  -webkit-box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2), 0px 21px 33px 3px rgba(0,0,0,0.14), 0px 8px 40px 7px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 10px 13px -6px rgba(0,0,0,0.2), 0px 21px 33px 3px rgba(0,0,0,0.14), 0px 8px 40px 7px rgba(0,0,0,0.12) !important;
}
.elevation-22 {
  -webkit-box-shadow: 0px 10px 14px -6px rgba(0,0,0,0.2), 0px 22px 35px 3px rgba(0,0,0,0.14), 0px 8px 42px 7px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 10px 14px -6px rgba(0,0,0,0.2), 0px 22px 35px 3px rgba(0,0,0,0.14), 0px 8px 42px 7px rgba(0,0,0,0.12) !important;
}
.elevation-23 {
  -webkit-box-shadow: 0px 11px 14px -7px rgba(0,0,0,0.2), 0px 23px 36px 3px rgba(0,0,0,0.14), 0px 9px 44px 8px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 11px 14px -7px rgba(0,0,0,0.2), 0px 23px 36px 3px rgba(0,0,0,0.14), 0px 9px 44px 8px rgba(0,0,0,0.12) !important;
}
.elevation-24 {
  -webkit-box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important;
          box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important;
}
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-y: scroll; /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%; /* iOS 8+ */
}
*, ::before, ::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
::before, ::after {
  text-decoration: inherit; /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}
* {
  background-repeat: no-repeat; /* Set `background-repeat: no-repeat` to all elements */
  padding: 0; /* Reset `padding` and `margin` of all elements */
  margin: 0;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
hr {
  overflow: visible; /* Show the overflow in Edge and IE */
}
article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}
summary {
  display: list-item; /* Add the correct display in all browsers */
}
small {
  font-size: 80%; /* Set font-size to 80% in `small` elements */
}
[hidden], template {
  display: none; /* Add the correct display in IE */
}
abbr[title] {
  border-bottom: 1px dotted; /* Add a bordered underline effect in all browsers */
  text-decoration: none; /* Remove text decoration in Firefox 40+ */
}
a {
  background-color: transparent; /* Remove the gray background on active links in IE 10 */
  -webkit-text-decoration-skip: objects; /* Remove gaps in links underline in iOS 8+ and Safari 8+ */
}
a:active, a:hover {
  outline-width: 0; /* Remove the outline when hovering in all browsers */
}
code, kbd, pre, samp {
  font-family: monospace, monospace; /* Specify the font family of code elements */
}
b, strong {
  font-weight: bolder; /* Correct style set to `bold` in Edge 12+, Safari 6.2+, and Chrome 18+ */
}
dfn {
  font-style: italic; /* Address styling not present in Safari and Chrome */
}
mark {
  background-color: #ff0;
  color: #000;
}
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
input {
  border-radius: 0;
}
button, [type="button"], [type="reset"], [type="submit"], [role="button"] {
  cursor: pointer;
}
[disabled] {
  cursor: default;
}
[type="number"] {
  width: auto; /* Firefox 36+ */
}
[type="search"] {
  -webkit-appearance: textfield; /* Safari 8+ */
}
[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; /* Safari 8 */
}
textarea {
  overflow: auto; /* Internet Explorer 11+ */
  resize: vertical; /* Specify textarea resizability */
}
button, input, optgroup, select, textarea {
  font: inherit; /* Specify font inheritance of form elements */
}
optgroup {
  font-weight: bold; /* Restore the font weight unset by the previous rule. */
}
button {
  overflow: visible; /* Address `overflow` set to `hidden` in IE 8/9/10/11 */
}
button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}
button:-moz-focusring, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  outline: 0;
  border: 0;
}
button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; /* Correct the inability to style clickable types in iOS */
}
button, select {
  text-transform: none; /* Firefox 40+, Internet Explorer 11- */
}
button, input, select, textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
}
select {
  -moz-appearance: none; /* Firefox 36+ */
  -webkit-appearance: none; /* Chrome 41+ */
}
select::-ms-expand {
  display: none; /* Internet Explorer 11+ */
}
select::-ms-value {
  color: currentColor; /* Internet Explorer 11+ */
}
legend {
  border: 0; /* Correct `color` not being inherited in IE 8/9/10/11 */
  color: inherit; /* Correct the color inheritance from `fieldset` elements in IE */
  display: table; /* Correct the text wrapping in Edge and IE */
  max-width: 100%; /* Correct the text wrapping in Edge and IE */
  white-space: normal; /* Correct the text wrapping in Edge and IE */
}
::-webkit-file-upload-button {
  -webkit-appearance: button; /* Correct the inability to style clickable types in iOS and Safari */
  font: inherit; /* Change font properties to `inherit` in Chrome and Safari */
}
[type="search"] {
  -webkit-appearance: textfield; /* Correct the odd appearance in Chrome and Safari */
  outline-offset: -2px; /* Correct the outline style in Safari */
}
img {
  border-style: none; /* Remove border when inside `a` element in IE 8/9/10 */
}
progress {
  vertical-align: baseline;
}
svg:not(:root) {
  overflow: hidden; /* Internet Explorer 11- */
}
audio, canvas, progress, video {
  display: inline-block; /* Internet Explorer 11+, Windows Phone 8.1+ */
}
@media screen {
  [hidden~="screen"] {
    display: inherit;
  }
  [hidden~="screen"]:not(:active):not(:focus):not(:target) {
    position: absolute !important;
    clip: rect(0 0 0 0) !important;
  }
}
[aria-busy="true"] {
  cursor: progress;
}
[aria-controls] {
  cursor: pointer;
}
[aria-disabled] {
  cursor: default;
}
::-moz-selection {
  background-color: #b3d4fc; /* Required when declaring ::selection */
  color: #000;
  text-shadow: none;
}
::selection {
  background-color: #b3d4fc; /* Required when declaring ::selection */
  color: #000;
  text-shadow: none;
}
.bottom-sheet-transition-enter {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.bottom-sheet-transition-leave-to {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.carousel-transition-enter {
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}
.carousel-transition-leave, .carousel-transition-leave-to {
  position: absolute;
  top: 0;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}
.carousel-reverse-transition-enter {
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}
.carousel-reverse-transition-leave, .carousel-reverse-transition-leave-to {
  position: absolute;
  top: 0;
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}
.dialog-transition-enter, .dialog-transition-leave-to {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  opacity: 0;
}
.dialog-transition-enter-to, .dialog-transition-leave {
  opacity: 1;
}
.dialog-bottom-transition-enter, .dialog-bottom-transition-leave-to {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.picker-transition-enter-active, .picker-reverse-transition-enter-active, .picker-transition-leave-active, .picker-reverse-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.picker-transition-enter, .picker-reverse-transition-enter, .picker-transition-leave-to, .picker-reverse-transition-leave-to {
  opacity: 0;
}
.picker-transition-enter-to, .picker-reverse-transition-enter-to {
  transtion: translate(0, 0);
}
.picker-transition-leave, .picker-reverse-transition-leave, .picker-transition-leave-active, .picker-reverse-transition-leave-active, .picker-transition-leave-to, .picker-reverse-transition-leave-to {
  position: absolute !important;
}
.picker-transition-enter {
  -webkit-transform: translate(0, 100%);
          transform: translate(0, 100%);
}
.picker-transition-leave-to {
  -webkit-transform: translate(0, -100%);
          transform: translate(0, -100%);
}
.picker-reverse-transition-enter {
  -webkit-transform: translate(0, -100%);
          transform: translate(0, -100%);
}
.picker-reverse-transition-leave-to {
  -webkit-transform: translate(0, 100%);
          transform: translate(0, 100%);
}
.picker-title-transition-enter-to, .picker-title-transition-leave {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.picker-title-transition-enter {
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}
.picker-title-transition-leave-to {
  opacity: 0;
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}
.picker-title-transition-leave, .picker-title-transition-leave-to, .picker-title-transition-leave-active {
  position: absolute !important;
}
.tab-transition-enter {
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}
.tab-transition-leave, .tab-transition-leave-active {
  position: absolute;
  top: 0;
}
.tab-transition-leave-to {
  position: absolute;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}
.tab-reverse-transition-enter {
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}
.tab-reverse-transition-leave, .tab-reverse-transition-leave-to {
  top: 0;
  position: absolute;
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}
.scale-transition-enter-active, .scale-transition-leave-active {
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.scale-transition-enter, .scale-transition-leave, .scale-transition-leave-to {
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.message-transition-enter-active, .message-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.message-transition-enter, .message-transition-leave-to {
  opacity: 0;
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
}
.message-transition-leave, .message-transition-leave-active {
  position: absolute;
}
.slide-y-transition-enter-active, .slide-y-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slide-y-transition-enter, .slide-y-transition-leave-to {
  opacity: 0;
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
}
.slide-y-reverse-transition-enter-active, .slide-y-reverse-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slide-y-reverse-transition-enter, .slide-y-reverse-transition-leave-to {
  opacity: 0;
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
}
.slide-x-transition-enter-active, .slide-x-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slide-x-transition-enter, .slide-x-transition-leave-to {
  opacity: 0;
  -webkit-transform: translateX(-15px);
          transform: translateX(-15px);
}
.slide-x-reverse-transition-enter-active, .slide-x-reverse-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.slide-x-reverse-transition-enter, .slide-x-reverse-transition-leave-to {
  opacity: 0;
  -webkit-transform: translateX(15px);
          transform: translateX(15px);
}
.fade-transition-enter-active, .fade-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.fade-transition-enter, .fade-transition-leave-to {
  opacity: 0;
}
.fab-transition-enter-active, .fab-transition-leave-active {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.fab-transition-enter, .fab-transition-leave-to {
  -webkit-transform: scale(0) rotate(-45deg);
          transform: scale(0) rotate(-45deg);
}
.blockquote {
  padding: 16px 0 16px 24px;
  font-size: 18px;
  font-weight: 300;
}
code, kbd {
  display: inline-block;
  border-radius: 3px;
  white-space: pre-wrap;
  font-size: 85%;
  font-weight: 900;
}
code:after, kbd:after, code:before, kbd:before {
  content: "\A0";
  letter-spacing: -1px;
}
code {
  background-color: #f5f5f5;
  color: #bd4147;
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
kbd {
  background: #424242;
  color: #fff;
}
html {
  font-size: 14px;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.application {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1.5;
}
::-ms-clear, ::-ms-reveal {
  display: none;
}
ul, ol {
  padding-left: 24px;
}
.display-4 {
  font-size: 112px !important;
  font-weight: 300;
  line-height: 1 !important;
  letter-spacing: -0.04em !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
.display-3 {
  font-size: 56px !important;
  font-weight: 400;
  line-height: 1.35 !important;
  letter-spacing: -0.02em !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
.display-2 {
  font-size: 45px !important;
  font-weight: 400;
  line-height: 48px !important;
  letter-spacing: normal !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
.display-1 {
  font-size: 34px !important;
  font-weight: 400;
  line-height: 40px !important;
  letter-spacing: normal !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
.headline {
  font-size: 24px !important;
  font-weight: 400;
  line-height: 32px !important;
  letter-spacing: normal !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
.title {
  font-size: 20px !important;
  font-weight: 500;
  line-height: 1 !important;
  letter-spacing: 0.02em !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
.subheading {
  font-size: 16px !important;
  font-weight: 600;
}
.body-2 {
  font-size: 14px !important;
  font-weight: 500;
}
.body-1 {
  font-size: 14px !important;
  font-weight: 400;
}
.caption {
  font-size: 12px !important;
  font-weight: 400;
}
.v-btn {
  font-size: 14px;
  font-weight: 500;
}
p {
  margin-bottom: 16px;
}
@media only screen and (max-width: 599px) {
  .hidden-xs-only {
    display: none !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 959px) {
  .hidden-sm-only {
    display: none !important;
  }
}
@media only screen and (max-width: 959px) {
  .hidden-sm-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 600px) {
  .hidden-sm-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1263px) {
  .hidden-md-only {
    display: none !important;
  }
}
@media only screen and (max-width: 1263px) {
  .hidden-md-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 960px) {
  .hidden-md-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 1264px) and (max-width: 1903px) {
  .hidden-lg-only {
    display: none !important;
  }
}
@media only screen and (max-width: 1903px) {
  .hidden-lg-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 1264px) {
  .hidden-lg-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 1904px) {
  .hidden-xl-only {
    display: none !important;
  }
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.right {
  float: right !important;
}
.left {
  float: left !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.ma-0 {
  margin: 0 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.pa-0 {
  padding: 0 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.ma-1 {
  margin: 4px 4px !important;
}
.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.mx-1 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.mt-1 {
  margin-top: 4px !important;
}
.mr-1 {
  margin-right: 4px !important;
}
.mb-1 {
  margin-bottom: 4px !important;
}
.ml-1 {
  margin-left: 4px !important;
}
.pa-1 {
  padding: 4px 4px !important;
}
.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.pt-1 {
  padding-top: 4px !important;
}
.pr-1 {
  padding-right: 4px !important;
}
.pb-1 {
  padding-bottom: 4px !important;
}
.pl-1 {
  padding-left: 4px !important;
}
.ma-2 {
  margin: 8px 8px !important;
}
.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.mx-2 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.mt-2 {
  margin-top: 8px !important;
}
.mr-2 {
  margin-right: 8px !important;
}
.mb-2 {
  margin-bottom: 8px !important;
}
.ml-2 {
  margin-left: 8px !important;
}
.pa-2 {
  padding: 8px 8px !important;
}
.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.pt-2 {
  padding-top: 8px !important;
}
.pr-2 {
  padding-right: 8px !important;
}
.pb-2 {
  padding-bottom: 8px !important;
}
.pl-2 {
  padding-left: 8px !important;
}
.ma-3 {
  margin: 16px 16px !important;
}
.my-3 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.mx-3 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.mt-3 {
  margin-top: 16px !important;
}
.mr-3 {
  margin-right: 16px !important;
}
.mb-3 {
  margin-bottom: 16px !important;
}
.ml-3 {
  margin-left: 16px !important;
}
.pa-3 {
  padding: 16px 16px !important;
}
.py-3 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.px-3 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.pt-3 {
  padding-top: 16px !important;
}
.pr-3 {
  padding-right: 16px !important;
}
.pb-3 {
  padding-bottom: 16px !important;
}
.pl-3 {
  padding-left: 16px !important;
}
.ma-4 {
  margin: 24px 24px !important;
}
.my-4 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.mx-4 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}
.mt-4 {
  margin-top: 24px !important;
}
.mr-4 {
  margin-right: 24px !important;
}
.mb-4 {
  margin-bottom: 24px !important;
}
.ml-4 {
  margin-left: 24px !important;
}
.pa-4 {
  padding: 24px 24px !important;
}
.py-4 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.px-4 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.pt-4 {
  padding-top: 24px !important;
}
.pr-4 {
  padding-right: 24px !important;
}
.pb-4 {
  padding-bottom: 24px !important;
}
.pl-4 {
  padding-left: 24px !important;
}
.ma-5 {
  margin: 48px 48px !important;
}
.my-5 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}
.mx-5 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}
.mt-5 {
  margin-top: 48px !important;
}
.mr-5 {
  margin-right: 48px !important;
}
.mb-5 {
  margin-bottom: 48px !important;
}
.ml-5 {
  margin-left: 48px !important;
}
.pa-5 {
  padding: 48px 48px !important;
}
.py-5 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}
.px-5 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}
.pt-5 {
  padding-top: 48px !important;
}
.pr-5 {
  padding-right: 48px !important;
}
.pb-5 {
  padding-bottom: 48px !important;
}
.pl-5 {
  padding-left: 48px !important;
}
@media all and (min-width: 0) {
  .text-xs-left {
    text-align: left !important;
  }
  .text-xs-center {
    text-align: center !important;
  }
  .text-xs-right {
    text-align: right !important;
  }
  .text-xs-justify {
    text-align: justify !important;
  }
}
@media all and (min-width: 600px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-justify {
    text-align: justify !important;
  }
}
@media all and (min-width: 960px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-justify {
    text-align: justify !important;
  }
}
@media all and (min-width: 1264px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-justify {
    text-align: justify !important;
  }
}
@media all and (min-width: 1904px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-justify {
    text-align: justify !important;
  }
}
.font-weight-thin {
  font-weight: 100 !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-regular {
  font-weight: 400 !important;
}
.font-weight-medium {
  font-weight: 500 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-black {
  font-weight: 900 !important;
}
.font-italic {
  font-style: italic !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-no-wrap {
  white-space: nowrap !important;
}
.text-truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
a {
  text-decoration: none;
}
.app-toolbar {
  z-index: 5 !important;
  background-color: #00638f !important;
}
.container.container--max-lg {
  max-width: 1264px;
}
.align-self-end {
  -ms-flex-item-align: end;
      align-self: flex-end;
}
.layout--full-height .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.layout--full-height .flex > .card {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.v-content {
  position: relative;
  z-index: 0;
}
.text--nowrap {
  white-space: nowrap;
}
.v-list--bordered {
  border: 1px solid rgba(0,0,0,0.12);
}
.list--unstyled {
  list-style-type: none;
  padding-left: 0;
}
.list--inline {
  padding-left: 0;
  margin-left: -5px;
  margin-bottom: 5px;
}
.list--inline > li {
  display: inline-block;
  list-style: none;
  padding-left: 5px;
  padding-right: 5px;
}
.hidden {
  display: none !important;
}
.material-design-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-item-align: center;
      align-self: center;
  position: relative;
  font-size: 24px;
  line-height: 24px;
  height: 1em;
  width: 1em;
}
.material-design-icon__svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
  position: absolute;
}
.v-list__tile--divider {
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
.dialog__heading {
  position: relative;
}
.dialog__title {
  padding-right: 26px;
}
.dialog__close {
  position: absolute !important;
  top: 11px;
  right: 0;
}
.v-alert {
  border-radius: 0;
  border-width: 4px 0 0 0;
  border-style: solid;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  margin: 4px auto;
  padding: 16px;
  position: relative;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-alert .v-alert__icon.v-icon, .v-alert__dismissible .v-icon {
  -ms-flex-item-align: center;
      align-self: center;
  color: rgba(0,0,0,0.3);
  font-size: 24px;
}
.v-alert--outline .v-icon {
  color: inherit !important;
}
.v-alert__icon {
  margin-right: 16px;
}
.v-alert__dismissible {
  -ms-flex-item-align: start;
      align-self: flex-start;
  color: inherit;
  margin-left: 16px;
  margin-right: 0;
  text-decoration: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-alert__dismissible:hover {
  opacity: 0.8;
}
.v-alert--no-icon .v-alert__icon {
  display: none;
}
.v-alert > div {
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
}
@media screen and (max-width: 600px) {
  .v-alert__icon {
    display: none;
  }
}
.v-alert.v-alert {
  border-color: rgba(0,0,0,0.12) !important;
}
.v-alert.v-alert--outline {
  border: 1px solid currentColor !important;
}
.application {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.application--is-rtl {
  direction: rtl;
}
.application--wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}
.application.theme--light {
  background: #fafafa;
  color: rgba(0,0,0,0.87);
}
.application.theme--light a {
  cursor: pointer;
}
.application.theme--light .text--primary {
  color: rgba(0,0,0,0.87) !important;
}
.application.theme--light .text--secondary {
  color: rgba(0,0,0,0.54) !important;
}
.application.theme--light .text--disabled {
  color: rgba(0,0,0,0.38) !important;
}
.application.theme--dark {
  background: #303030;
  color: #fff;
}
.application.theme--dark a {
  cursor: pointer;
}
.application.theme--dark .text--primary {
  color: #fff !important;
}
.application.theme--dark .text--secondary {
  color: rgba(255,255,255,0.7) !important;
}
.application.theme--dark .text--disabled {
  color: rgba(255,255,255,0.5) !important;
}
@-moz-document url-prefix() {
@media print {
    .application {
      display: block;
    }
    .application--wrap {
      display: block;
    }
}
}
.v-autocomplete.v-input, .v-autocomplete .v-menu__activator, .v-autocomplete .v-menu__activator * {
  cursor: text;
}
.v-autocomplete input {
  -ms-flex-item-align: center;
      align-self: center;
}
.v-autocomplete--is-selecting-index input {
  opacity: 0;
}
.v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo) .v-select__slot > input {
  margin-top: 24px;
}
.v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
  pointer-events: auto;
}
.v-autocomplete__content.v-menu__content {
  border-radius: 0;
}
.v-autocomplete__content.v-menu__content .v-card {
  border-radius: 0;
}
.v-avatar {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  text-align: center;
  vertical-align: middle;
}
.v-avatar img, .v-avatar .v-icon {
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: inherit;
  width: inherit;
}
.v-avatar--tile {
  border-radius: 0;
}
.v-avatar--tile img, .v-avatar--tile .v-icon {
  border-radius: 0;
}
.v-badge {
  display: inline-block;
  position: relative;
}
.v-badge__badge {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  font-size: 14px;
  top: -11px;
  right: -22px;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-badge__badge .v-icon {
  font-size: 14px;
}
.v-badge--overlap .v-badge__badge {
  top: -8px;
  right: -8px;
}
.v-badge--overlap.v-badge--left .v-badge__badge {
  left: -8px;
  right: initial;
}
.v-badge--overlap.v-badge--bottom .v-badge__badge {
  bottom: -8px;
  top: initial;
}
.v-badge--left .v-badge__badge {
  left: -22px;
}
.v-badge--bottom .v-badge__badge {
  bottom: -11px;
  top: initial;
}
.theme--light .v-bottom-nav, .application .theme--light.v-bottom-nav {
  background-color: #fff;
}
.theme--dark .v-bottom-nav, .application .theme--dark.v-bottom-nav {
  background-color: #424242;
}
.v-bottom-nav {
  bottom: 0;
  -webkit-box-shadow: 0 3px 14px 2px rgba(0,0,0,0.12);
          box-shadow: 0 3px 14px 2px rgba(0,0,0,0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: translate(0, 60px);
          transform: translate(0, 60px);
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-bottom-nav--absolute {
  position: absolute;
}
.v-bottom-nav--active {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.v-bottom-nav--fixed {
  position: fixed;
  z-index: 4;
}
.v-bottom-nav .v-btn {
  background: transparent !important;
  border-radius: 0;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  font-weight: 400;
  height: 100%;
  margin: 0;
  max-width: 168px;
  min-width: 80px;
  padding: 8px 12px 10px;
  text-transform: none;
  opacity: 0.5;
  width: 100%;
  -ms-flex-negative: 1;
      flex-shrink: 1;
}
.v-bottom-nav .v-btn .v-btn__content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  font-size: 12px;
  white-space: nowrap;
  will-change: font-size;
}
.v-bottom-nav .v-btn .v-btn__content i.v-icon {
  color: inherit;
  margin-bottom: 4px;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-bottom-nav .v-btn .v-btn__content span {
  line-height: 1;
}
.v-bottom-nav .v-btn--active {
  opacity: 1;
  padding-top: 6px;
}
.v-bottom-nav .v-btn--active:before {
  background-color: transparent;
}
.v-bottom-nav .v-btn--active .v-btn__content {
  font-size: 14px;
}
.v-bottom-nav .v-btn--active .v-btn__content .v-icon {
  -webkit-transform: none;
          transform: none;
}
.v-bottom-nav .v-btn:not(.v-btn--active) {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.v-bottom-nav--shift .v-btn__content {
  font-size: 14px;
}
.v-bottom-nav--shift .v-btn {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  min-width: 56px;
  max-width: 96px;
}
.v-bottom-nav--shift .v-btn--active {
  min-width: 96px;
  max-width: 168px;
}
.v-bottom-nav--shift .v-btn:not(.v-btn--active) .v-btn__content .v-icon {
  -webkit-transform: scale(1, 1) translate(0, 8px);
          transform: scale(1, 1) translate(0, 8px);
}
.v-bottom-nav--shift .v-btn:not(.v-btn--active) .v-btn__content span {
  color: transparent;
}
.v-bottom-sheet.v-dialog {
  -ms-flex-item-align: end;
      align-self: flex-end;
  border-radius: 0;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  margin: 0;
  min-width: 100%;
  overflow: visible;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.v-bottom-sheet.v-dialog.v-bottom-sheet--inset {
  max-width: 70%;
  min-width: 0;
}
@media only screen and (max-width: 599px) {
  .v-bottom-sheet.v-dialog.v-bottom-sheet--inset {
    max-width: none;
  }
}
.theme--light .v-breadcrumbs li.v-breadcrumbs__divider, .application .theme--light.v-breadcrumbs li.v-breadcrumbs__divider, .theme--light .v-breadcrumbs li:last-child .v-breadcrumbs__item, .application .theme--light.v-breadcrumbs li:last-child .v-breadcrumbs__item, .theme--light .v-breadcrumbs li .v-breadcrumbs__item--disabled, .application .theme--light.v-breadcrumbs li .v-breadcrumbs__item--disabled {
  color: rgba(0,0,0,0.38);
}
.theme--dark .v-breadcrumbs li.v-breadcrumbs__divider, .application .theme--dark.v-breadcrumbs li.v-breadcrumbs__divider, .theme--dark .v-breadcrumbs li:last-child .v-breadcrumbs__item, .application .theme--dark.v-breadcrumbs li:last-child .v-breadcrumbs__item, .theme--dark .v-breadcrumbs li .v-breadcrumbs__item--disabled, .application .theme--dark.v-breadcrumbs li .v-breadcrumbs__item--disabled {
  color: rgba(255,255,255,0.5);
}
.v-breadcrumbs {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  list-style-type: none;
  margin: 0;
  padding: 18px 12px;
}
.v-breadcrumbs li {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
}
.v-breadcrumbs li .v-icon {
  font-size: 16px;
}
.v-breadcrumbs li:last-child a {
  cursor: default;
  pointer-events: none;
}
.v-breadcrumbs li:nth-child(even) {
  padding: 0 12px;
}
.v-breadcrumbs--large li {
  font-size: 16px;
}
.v-breadcrumbs--large li .v-icon {
  font-size: 16px;
}
.v-breadcrumbs__item {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-breadcrumbs__item--disabled {
  pointer-events: none;
}
.theme--light .v-btn-toggle, .application .theme--light.v-btn-toggle {
  background: #fff;
}
.theme--light .v-btn-toggle .v-btn, .application .theme--light.v-btn-toggle .v-btn {
  color: rgba(0,0,0,0.87);
}
.theme--light .v-btn-toggle .v-btn.v-btn--active:not(:last-child):not([data-only-child]), .application .theme--light.v-btn-toggle .v-btn.v-btn--active:not(:last-child):not([data-only-child]) {
  border-right-color: rgba(0,0,0,0.26);
}
.theme--dark .v-btn-toggle, .application .theme--dark.v-btn-toggle {
  background: #424242;
}
.theme--dark .v-btn-toggle .v-btn, .application .theme--dark.v-btn-toggle .v-btn {
  color: #fff;
}
.theme--dark .v-btn-toggle .v-btn.v-btn--active:not(:last-child):not([data-only-child]), .application .theme--dark.v-btn-toggle .v-btn.v-btn--active:not(:last-child):not([data-only-child]) {
  border-right-color: rgba(255,255,255,0.3);
}
.v-btn-toggle {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 2px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  will-change: background, box-shadow;
}
.v-btn-toggle .v-btn {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-width: auto;
  width: auto;
  padding: 0 8px;
  margin: 0;
  opacity: 0.4;
  border-radius: 0;
}
.v-btn-toggle .v-btn:not(:last-child) {
  border-right: 1px solid transparent;
}
.v-btn-toggle .v-btn:after {
  display: none;
}
.v-btn-toggle .v-btn.v-btn--active {
  opacity: 1;
}
.v-btn-toggle .v-btn span + .v-icon {
  font-size: initial;
  margin-left: 10px;
}
.v-btn-toggle .v-btn:first-child {
  border-radius: 2px 0 0 2px;
}
.v-btn-toggle .v-btn:last-child {
  border-radius: 0 2px 2px 0;
}
.v-btn-toggle--selected {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.v-ripple__container {
  color: inherit;
  border-radius: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
  contain: strict;
}
.v-ripple__animation {
  color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: currentColor;
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  pointer-events: none;
  overflow: hidden;
  will-change: transform, opacity;
}
.v-ripple__animation--enter {
  -webkit-transition: none;
  transition: none;
}
.v-ripple__animation--visible {
  opacity: 0.15;
}
.theme--light .v-btn, .application .theme--light.v-btn {
  color: rgba(0,0,0,0.87);
}
.theme--light .v-btn.v-btn--disabled, .application .theme--light.v-btn.v-btn--disabled {
  color: rgba(0,0,0,0.26) !important;
}
.theme--light .v-btn.v-btn--disabled .v-icon, .application .theme--light.v-btn.v-btn--disabled .v-icon, .theme--light .v-btn.v-btn--disabled .v-btn__loading, .application .theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: rgba(0,0,0,0.26) !important;
}
.theme--light .v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat), .application .theme--light.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: rgba(0,0,0,0.12) !important;
}
.theme--light .v-btn:not(.v-btn--icon):not(.v-btn--flat), .application .theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #f5f5f5;
}
.theme--dark .v-btn, .application .theme--dark.v-btn {
  color: #fff;
}
.theme--dark .v-btn.v-btn--disabled, .application .theme--dark.v-btn.v-btn--disabled {
  color: rgba(255,255,255,0.3) !important;
}
.theme--dark .v-btn.v-btn--disabled .v-icon, .application .theme--dark.v-btn.v-btn--disabled .v-icon, .theme--dark .v-btn.v-btn--disabled .v-btn__loading, .application .theme--dark.v-btn.v-btn--disabled .v-btn__loading {
  color: rgba(255,255,255,0.3) !important;
}
.theme--dark .v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat), .application .theme--dark.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: rgba(255,255,255,0.12) !important;
}
.theme--dark .v-btn:not(.v-btn--icon):not(.v-btn--flat), .application .theme--dark.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #212121;
}
.v-btn {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 2px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 36px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  font-size: 14px;
  font-weight: 500;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 6px 8px;
  min-width: 88px;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  position: relative;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-btn:before {
  border-radius: inherit;
  color: inherit;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0.12;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-btn {
  padding: 0 16px;
}
.v-btn--active, .v-btn:hover, .v-btn:focus {
  position: relative;
}
.v-btn--active:before, .v-btn:hover:before, .v-btn:focus:before {
  background-color: currentColor;
}
.v-btn__content {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: inherit;
  color: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  position: relative;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  width: inherit;
}
.v-btn--small {
  font-size: 13px;
  height: 28px;
  padding: 0 8px;
}
.v-btn--large {
  font-size: 15px;
  height: 44px;
  padding: 0 32px;
}
.v-btn .v-btn__content .v-icon {
  color: inherit;
}
.v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
  will-change: box-shadow;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.v-btn:not(.v-btn--depressed):not(.v-btn--flat):active {
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
          box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
.v-btn--icon {
  background: transparent;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 50%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-width: 0;
  width: 36px;
}
.v-btn--icon.v-btn--small {
  width: 28px;
}
.v-btn--icon.v-btn--large {
  width: 44px;
}
.v-btn--icon:before {
  border-radius: 50%;
}
.v-btn--floating {
  border-radius: 50%;
  min-width: 0;
  height: 56px;
  width: 56px;
  padding: 0;
}
.v-btn--floating.v-btn--fixed, .v-btn--floating.v-btn--absolute {
  z-index: 4;
}
.v-btn--floating:not(.v-btn--depressed):not(.v-btn--flat) {
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
}
.v-btn--floating:not(.v-btn--depressed):not(.v-btn--flat):active {
  -webkit-box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12);
          box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12);
}
.v-btn--floating .v-btn__content {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  margin: 0;
}
.v-btn--floating:after {
  border-radius: 50%;
}
.v-btn--floating .v-btn__content :not(:only-child) {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-btn--floating .v-btn__content :not(:only-child):first-child {
  opacity: 1;
}
.v-btn--floating .v-btn__content :not(:only-child):last-child {
  opacity: 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.v-btn--floating .v-btn__content :not(:only-child):last-child, .v-btn--floating .v-btn__content :not(:only-child):first-child {
  -webkit-backface-visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
}
.v-btn--floating.v-btn--active .v-btn__content :not(:only-child):first-child {
  opacity: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.v-btn--floating.v-btn--active .v-btn__content :not(:only-child):last-child {
  opacity: 1;
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
.v-btn--floating .v-icon {
  height: inherit;
  width: inherit;
}
.v-btn--floating.v-btn--small {
  height: 40px;
  width: 40px;
}
.v-btn--floating.v-btn--small .v-icon {
  font-size: 18px;
}
.v-btn--floating.v-btn--large {
  height: 72px;
  width: 72px;
}
.v-btn--floating.v-btn--large .v-icon {
  font-size: 30px;
}
.v-btn--reverse .v-btn__content {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.v-btn--reverse.v-btn--column .v-btn__content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.v-btn--fixed, .v-btn--absolute {
  margin: 0;
}
.v-btn.v-btn--absolute {
  position: absolute;
}
.v-btn.v-btn--fixed {
  position: fixed;
}
.v-btn--top:not(.v-btn--absolute) {
  top: 16px;
}
.v-btn--top.v-btn--absolute {
  top: -28px;
}
.v-btn--top.v-btn--absolute.v-btn--small {
  top: -20px;
}
.v-btn--top.v-btn--absolute.v-btn--large {
  top: -36px;
}
.v-btn--bottom:not(.v-btn--absolute) {
  bottom: 16px;
}
.v-btn--bottom.v-btn--absolute {
  bottom: -28px;
}
.v-btn--bottom.v-btn--absolute.v-btn--small {
  bottom: -20px;
}
.v-btn--bottom.v-btn--absolute.v-btn--large {
  bottom: -36px;
}
.v-btn--left {
  left: 16px;
}
.v-btn--right {
  right: 16px;
}
.v-btn.v-btn--disabled {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  pointer-events: none;
}
.v-btn:not(.v-btn--disabled):not(.v-btn--floating):not(.v-btn--icon) .v-btn__content .v-icon {
  -webkit-transition: none;
  transition: none;
}
.v-btn--icon {
  padding: 0;
}
.v-btn--loader {
  pointer-events: none;
}
.v-btn--loader .v-btn__content {
  opacity: 0;
}
.v-btn__loading {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.v-btn__loading .v-icon--left {
  margin-right: 1rem;
  line-height: inherit;
}
.v-btn__loading .v-icon--right {
  margin-left: 1rem;
  line-height: inherit;
}
.v-btn.v-btn--outline {
  border: 1px solid currentColor;
  background: transparent !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.v-btn.v-btn--outline:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.v-btn--block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 6px 0;
  width: 100%;
}
.v-btn--round {
  border-radius: 28px;
}
.v-btn--round:after {
  border-radius: 28px;
}
.v-btn .v-icon--right {
  margin-left: 16px;
}
.v-btn .v-icon--left {
  margin-right: 16px;
}
.v-btn.primary, .v-btn.secondary, .v-btn.accent, .v-btn.success, .v-btn.error, .v-btn.warning, .v-btn.info {
  color: #fff;
}
.theme--light .v-card, .application .theme--light.v-card {
  background-color: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--dark .v-card, .application .theme--dark.v-card {
  background-color: #424242;
  color: #fff;
}
.v-card {
  display: block;
  border-radius: 2px;
  min-width: 0;
  position: relative;
  text-decoration: none;
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.v-card > *:first-child:not(.v-btn):not(.v-chip) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.v-card > *:last-child:not(.v-btn):not(.v-chip) {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.v-card--raised {
  -webkit-box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}
.v-card--tile {
  border-radius: 0;
}
.v-card--flat {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
}
.v-card--hover {
  cursor: pointer;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-transition-property: -webkit-box-shadow;
  transition-property: -webkit-box-shadow;
  transition-property: box-shadow;
  transition-property: box-shadow, -webkit-box-shadow;
}
.v-card--hover:hover {
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
          box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
.v-card__title {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 16px;
}
.v-card__title--primary {
  padding-top: 24px;
}
.v-card__text {
  padding: 16px;
  width: 100%;
}
.v-card__media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  position: relative;
}
.v-card__media img {
  width: 100%;
}
.v-card__media__background {
  border-radius: inherit;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.v-card__media__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  position: relative;
}
.v-card__actions {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
}
.v-card__actions > *, .v-card__actions .v-btn {
  margin: 0;
}
.v-card__actions .v-btn + .v-btn {
  margin-left: 8px;
}
.application--is-rtl .v-carousel__prev {
  left: auto;
  right: 5px;
}
.application--is-rtl .v-carousel__next {
  left: 5px;
  right: auto;
}
.v-carousel {
  height: 500px;
  width: 100%;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.v-carousel__prev, .v-carousel__next {
  position: absolute;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.v-carousel__prev .v-btn, .v-carousel__next .v-btn {
  color: #fff;
  margin: 0 !important;
  height: auto;
  width: auto;
}
.v-carousel__prev .v-btn i, .v-carousel__next .v-btn i {
  font-size: 48px;
}
.v-carousel__prev .v-btn:hover, .v-carousel__next .v-btn:hover {
  background: none;
}
.v-carousel__prev {
  left: 5px;
}
.v-carousel__next {
  right: 5px;
}
.v-carousel__controls {
  background: rgba(0,0,0,0.5);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  position: absolute;
  height: 50px;
  list-style-type: none;
  width: 100%;
  z-index: 1;
}
.v-carousel__controls__item {
  color: #fff;
  margin: 0 8px !important;
}
.v-carousel__controls__item i {
  opacity: 0.5;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-carousel__controls__item--active i {
  opacity: 1;
  vertical-align: middle;
}
.v-carousel__controls__item:hover {
  background: none;
}
.v-carousel__controls__item:hover i {
  opacity: 0.8;
}
.theme--light .v-chip, .application .theme--light.v-chip {
  background: #e0e0e0;
  color: rgba(0,0,0,0.87);
}
.theme--light .v-chip--disabled, .application .theme--light.v-chip--disabled {
  color: rgba(0,0,0,0.38);
}
.theme--dark .v-chip, .application .theme--dark.v-chip {
  background: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--dark .v-chip--disabled, .application .theme--dark.v-chip--disabled {
  color: rgba(255,255,255,0.5);
}
.v-chip {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 28px;
  border: 1px solid transparent;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 13px;
  margin: 4px;
  outline: none;
  position: relative;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  vertical-align: middle;
}
.v-chip .v-chip__content {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 28px;
  cursor: default;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 32px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 12px;
  vertical-align: middle;
  white-space: nowrap;
  z-index: 1;
}
.v-chip--removable .v-chip__content {
  padding: 0 4px 0 12px;
}
.v-chip .v-avatar {
  height: 32px !important;
  margin-left: -12px;
  margin-right: 8px;
  min-width: 32px;
  width: 32px !important;
}
.v-chip .v-avatar img {
  height: 100%;
  width: 100%;
}
.v-chip:focus:not(.v-chip--disabled), .v-chip--active, .v-chip--selected {
  border-color: rgba(0,0,0,0.13);
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.v-chip:focus:not(.v-chip--disabled):after, .v-chip--active:after, .v-chip--selected:after {
  background: currentColor;
  border-radius: inherit;
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: inherit;
  transition: inherit;
  width: 100%;
  pointer-events: none;
  opacity: 0.13;
}
.v-chip--label {
  border-radius: 2px;
}
.v-chip--label .v-chip__content {
  border-radius: 2px;
}
.v-chip.v-chip.v-chip--outline {
  background: transparent !important;
  border-color: currentColor;
  color: #9e9e9e;
}
.v-chip--small {
  height: 24px;
}
.v-chip--small .v-avatar {
  height: 24px;
  min-width: 24px;
  width: 24px;
}
.v-chip--small .v-icon {
  font-size: 20px;
}
.v-chip__close {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 20px;
  margin: 0 2px 0 8px;
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-chip__close > .v-icon {
  color: inherit !important;
  font-size: 20px;
  cursor: pointer;
  opacity: 0.5;
}
.v-chip__close > .v-icon:hover {
  opacity: 1;
}
.v-chip--disabled .v-chip__close {
  pointer-events: none;
}
.v-chip--select-multi {
  margin: 4px 4px 4px 0;
}
.v-chip .v-icon {
  color: inherit;
}
.v-chip .v-icon--right {
  margin-left: 12px;
  margin-right: -8px;
}
.v-chip .v-icon--left {
  margin-left: -8px;
  margin-right: 12px;
}
.v-content {
  -webkit-transition: none;
  transition: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  max-width: 100%;
}
.v-content[data-booted="true"] {
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.v-content__wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  max-width: 100%;
  position: relative;
}
@-moz-document url-prefix() {
@media print {
    .v-content {
      display: block;
    }
}
}
.theme--light .v-counter, .application .theme--light.v-counter {
  color: rgba(0,0,0,0.54);
}
.theme--dark .v-counter, .application .theme--dark.v-counter {
  color: rgba(255,255,255,0.7);
}
.v-counter {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  font-size: 12px;
  min-height: 12px;
  line-height: 1;
}
.theme--light .v-data-iterator .v-data-iterator__actions, .application .theme--light.v-data-iterator .v-data-iterator__actions {
  color: rgba(0,0,0,0.54);
}
.theme--light .v-data-iterator .v-data-iterator__actions__select .v-select .v-select__selection--comma, .application .theme--light.v-data-iterator .v-data-iterator__actions__select .v-select .v-select__selection--comma {
  color: rgba(0,0,0,0.54) !important;
}
.theme--light .v-data-iterator .v-data-iterator__actions__select .v-select .v-input__append-inner, .application .theme--light.v-data-iterator .v-data-iterator__actions__select .v-select .v-input__append-inner {
  color: rgba(0,0,0,0.54) !important;
}
.theme--dark .v-data-iterator .v-data-iterator__actions, .application .theme--dark.v-data-iterator .v-data-iterator__actions {
  color: rgba(255,255,255,0.7);
}
.theme--dark .v-data-iterator .v-data-iterator__actions__select .v-select .v-select__selection--comma, .application .theme--dark.v-data-iterator .v-data-iterator__actions__select .v-select .v-select__selection--comma {
  color: rgba(255,255,255,0.7) !important;
}
.theme--dark .v-data-iterator .v-data-iterator__actions__select .v-select .v-input__append-inner, .application .theme--dark.v-data-iterator .v-data-iterator__actions__select .v-select .v-input__append-inner {
  color: rgba(255,255,255,0.7) !important;
}
.v-data-iterator__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}
.v-data-iterator__actions .v-btn {
  color: inherit;
}
.v-data-iterator__actions .v-btn:last-of-type {
  margin-left: 14px;
}
.v-data-iterator__actions__range-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 48px;
}
.v-data-iterator__actions__pagination {
  display: block;
  text-align: center;
  margin: 0 32px 0 24px;
}
.v-data-iterator__actions__select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 14px;
}
.v-data-iterator__actions__select .v-select {
  margin: 13px 0 13px 34px;
  padding: 0;
  position: initial;
}
.v-data-iterator__actions__select .v-select .v-select__selection--comma {
  font-size: 12px;
}
.theme--light .v-datatable thead th.column.sortable i, .application .theme--light.v-datatable thead th.column.sortable i {
  color: rgba(0,0,0,0.38);
}
.theme--light .v-datatable thead th.column.sortable:hover, .application .theme--light.v-datatable thead th.column.sortable:hover {
  color: rgba(0,0,0,0.87);
}
.theme--light .v-datatable thead th.column.sortable.active, .application .theme--light.v-datatable thead th.column.sortable.active {
  color: rgba(0,0,0,0.87);
}
.theme--light .v-datatable thead th.column.sortable.active i, .application .theme--light.v-datatable thead th.column.sortable.active i {
  color: rgba(0,0,0,0.87);
}
.theme--light .v-datatable .v-datatable__actions, .application .theme--light.v-datatable .v-datatable__actions {
  background-color: #fff;
  color: rgba(0,0,0,0.54);
  border-top: 1px solid rgba(0,0,0,0.12);
}
.theme--dark .v-datatable thead th.column.sortable i, .application .theme--dark.v-datatable thead th.column.sortable i {
  color: rgba(255,255,255,0.5);
}
.theme--dark .v-datatable thead th.column.sortable:hover, .application .theme--dark.v-datatable thead th.column.sortable:hover {
  color: #fff;
}
.theme--dark .v-datatable thead th.column.sortable.active, .application .theme--dark.v-datatable thead th.column.sortable.active {
  color: #fff;
}
.theme--dark .v-datatable thead th.column.sortable.active i, .application .theme--dark.v-datatable thead th.column.sortable.active i {
  color: #fff;
}
.theme--dark .v-datatable .v-datatable__actions, .application .theme--dark.v-datatable .v-datatable__actions {
  background-color: #424242;
  color: rgba(255,255,255,0.7);
  border-top: 1px solid rgba(255,255,255,0.12);
}
.v-datatable .v-input--selection-controls {
  margin: 0;
}
.v-datatable thead th.column.sortable {
  cursor: pointer;
  outline: 0;
}
.v-datatable thead th.column.sortable i {
  font-size: 16px;
  vertical-align: sub;
  display: inline-block;
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-datatable thead th.column.sortable:focus i, .v-datatable thead th.column.sortable:hover i {
  opacity: 0.6;
}
.v-datatable thead th.column.sortable.active {
  -webkit-transform: none;
          transform: none;
}
.v-datatable thead th.column.sortable.active i {
  opacity: 1;
}
.v-datatable thead th.column.sortable.active.desc i {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.v-datatable__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}
.v-datatable__actions .v-btn {
  color: inherit;
}
.v-datatable__actions .v-btn:last-of-type {
  margin-left: 14px;
}
.v-datatable__actions__range-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 48px;
}
.v-datatable__actions__pagination {
  display: block;
  text-align: center;
  margin: 0 32px 0 24px;
}
.v-datatable__actions__select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-right: 14px;
  white-space: nowrap;
}
.v-datatable__actions__select .v-select {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 0px;
          flex: 0 1 0;
  margin: 13px 0 13px 34px;
  padding: 0;
  position: initial;
}
.v-datatable__actions__select .v-select .v-select__selection--comma {
  font-size: 12px;
}
.v-datatable__progress {
  height: auto !important;
}
.v-datatable__progress tr, .v-datatable__progress td, .v-datatable__progress th {
  height: auto !important;
}
.v-datatable__progress th {
  padding: 0 !important;
}
.v-datatable__progress th .v-progress-linear {
  top: -2px;
  margin: 0 0 -2px;
}
.v-datatable__expand-row {
  border: none !important;
}
.v-datatable__expand-col {
  padding: 0 !important;
  height: 0px !important;
}
.v-datatable__expand-col--expanded {
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
.v-datatable__expand-content {
  -webkit-transition: height 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: height 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-datatable__expand-content > .card {
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.theme--light .v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) strong:not(:hover), .application .theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) strong:not(:hover) {
  color: rgba(0,0,0,0.87) !important;
}
.theme--light .v-date-picker-header .v-date-picker-header__value--disabled strong, .application .theme--light.v-date-picker-header .v-date-picker-header__value--disabled strong {
  color: rgba(0,0,0,0.38);
}
.theme--dark .v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) strong:not(:hover), .application .theme--dark.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) strong:not(:hover) {
  color: #fff !important;
}
.theme--dark .v-date-picker-header .v-date-picker-header__value--disabled strong, .application .theme--dark.v-date-picker-header .v-date-picker-header__value--disabled strong {
  color: rgba(255,255,255,0.5);
}
.v-date-picker-header {
  padding: 4px 16px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}
.v-date-picker-header .v-btn {
  margin: 0;
  z-index: auto;
}
.v-date-picker-header .v-icon {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-date-picker-header__value {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.v-date-picker-header__value strong {
  cursor: pointer;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  display: block;
  width: 100%;
}
.theme--light .v-date-picker-table th, .application .theme--light.v-date-picker-table th {
  color: rgba(0,0,0,0.38);
}
.theme--dark .v-date-picker-table th, .application .theme--dark.v-date-picker-table th {
  color: rgba(255,255,255,0.5);
}
.v-date-picker-table {
  position: relative;
  padding: 0 12px;
  height: 242px;
}
.v-date-picker-table table {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  top: 0;
  table-layout: fixed;
  width: 100%;
}
.v-date-picker-table td, .v-date-picker-table th {
  text-align: center;
  position: relative;
}
.v-date-picker-table th {
  font-size: 12px;
}
.v-date-picker-table--date .v-btn {
  height: 32px;
  width: 32px;
}
.v-date-picker-table .v-btn {
  z-index: auto;
  margin: 0;
  font-size: 12px;
}
.v-date-picker-table .v-btn.v-btn--active {
  color: #fff;
}
.v-date-picker-table--month td {
  width: 33.333333%;
  height: 56px;
  vertical-align: middle;
  text-align: center;
}
.v-date-picker-table--month td .v-btn {
  margin: 0 auto;
  max-width: 160px;
  min-width: 40px;
  width: 100%;
}
.v-date-picker-table--date th {
  padding: 8px 0;
  font-weight: 600;
}
.v-date-picker-table--date td {
  width: 45px;
}
.v-date-picker-table__event {
  border-radius: 50%;
  bottom: 2px;
  content: "";
  display: block;
  height: 8px;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-4px);
          transform: translateX(-4px);
  width: 8px;
}
.application--is-rtl .v-date-picker-title .v-picker__title__btn {
  text-align: right;
}
.v-date-picker-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  line-height: 1;
}
.v-date-picker-title__year {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}
.v-date-picker-title__date {
  font-size: 34px;
  text-align: left;
  font-weight: 500;
  position: relative;
  overflow: hidden;
}
.v-date-picker-title__date > div {
  position: relative;
}
.v-date-picker-years {
  font-size: 16px;
  font-weight: 400;
  height: 334px;
  list-style-type: none;
  overflow: auto;
  padding: 0;
  text-align: center;
}
.v-date-picker-years li {
  cursor: pointer;
  padding: 8px 0;
  -webkit-transition: none;
  transition: none;
}
.v-date-picker-years li.active {
  font-size: 26px;
  font-weight: 500;
  padding: 10px 0;
}
.v-date-picker-years li:hover {
  background: rgba(0,0,0,0.12);
}
.v-picker--landscape .v-date-picker-years {
  height: 286px;
}
.v-dialog {
  -webkit-box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
          box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
  border-radius: 2px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
}
.v-dialog__content {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  -webkit-transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: 6;
  outline: none;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 90%;
}
.v-dialog__activator {
  cursor: pointer;
}
.v-dialog__activator * {
  cursor: pointer;
}
.v-dialog__activator--disabled {
  pointer-events: none;
}
.v-dialog__container {
  display: inline-block;
  vertical-align: middle;
}
.v-dialog--animated {
  -webkit-animation-duration: 0.15s;
          animation-duration: 0.15s;
  -webkit-animation-name: animate-dialog;
          animation-name: animate-dialog;
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
          animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}
.v-dialog--fullscreen {
  border-radius: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
}
.v-dialog--fullscreen > .v-card {
  min-height: 100%;
  min-width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
.v-dialog--scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.v-dialog--scrollable > .v-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.v-dialog--scrollable > .v-card > .v-card__title, .v-dialog--scrollable > .v-card > .v-card__actions {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}
.v-dialog--scrollable > .v-card > .v-card__text {
  overflow-y: auto;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
@-webkit-keyframes animate-dialog {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes animate-dialog {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.theme--light .v-divider, .application .theme--light.v-divider {
  border-color: rgba(0,0,0,0.12);
}
.theme--dark .v-divider, .application .theme--dark.v-divider {
  border-color: rgba(255,255,255,0.12);
}
.v-divider {
  display: block;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0px;
  max-width: 100%;
  height: 0px;
  max-height: 0px;
  border: solid;
  border-width: thin 0 0 0;
}
.v-divider--inset:not(.v-divider--vertical) {
  margin-left: 72px;
  max-width: calc(100% - 72px);
}
.v-divider--vertical {
  width: 0px;
  max-width: 0px;
  height: 100%;
  max-height: 100%;
  border: solid;
  border-width: 0 thin 0 0;
}
.v-divider--vertical.v-divider--inset {
  max-height: calc(100% - 16px);
}
.theme--light .v-expansion-panel .v-expansion-panel__container, .application .theme--light.v-expansion-panel .v-expansion-panel__container {
  border-top: 1px solid rgba(0,0,0,0.12);
  background-color: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--light .v-expansion-panel .v-expansion-panel__container .v-expansion-panel__header .v-expansion-panel__header__icon .v-icon, .application .theme--light.v-expansion-panel .v-expansion-panel__container .v-expansion-panel__header .v-expansion-panel__header__icon .v-icon {
  color: rgba(0,0,0,0.54);
}
.theme--light .v-expansion-panel .v-expansion-panel__container--disabled, .application .theme--light.v-expansion-panel .v-expansion-panel__container--disabled {
  color: rgba(0,0,0,0.38);
}
.theme--light .v-expansion-panel--focusable .v-expansion-panel__container:focus, .application .theme--light.v-expansion-panel--focusable .v-expansion-panel__container:focus {
  background-color: #eee;
}
.theme--dark .v-expansion-panel .v-expansion-panel__container, .application .theme--dark.v-expansion-panel .v-expansion-panel__container {
  border-top: 1px solid rgba(255,255,255,0.12);
  background-color: #424242;
  color: #fff;
}
.theme--dark .v-expansion-panel .v-expansion-panel__container .v-expansion-panel__header .v-expansion-panel__header__icon .v-icon, .application .theme--dark.v-expansion-panel .v-expansion-panel__container .v-expansion-panel__header .v-expansion-panel__header__icon .v-icon {
  color: #fff;
}
.theme--dark .v-expansion-panel .v-expansion-panel__container--disabled, .application .theme--dark.v-expansion-panel .v-expansion-panel__container--disabled {
  color: rgba(255,255,255,0.5);
}
.theme--dark .v-expansion-panel--focusable .v-expansion-panel__container:focus, .application .theme--dark.v-expansion-panel--focusable .v-expansion-panel__container:focus {
  background-color: #494949;
}
.v-expansion-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  list-style-type: none;
  padding: 0;
  text-align: left;
  width: 100%;
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.v-expansion-panel__container {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  max-width: 100%;
  outline: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-expansion-panel__container:first-child {
  border-top: none !important;
}
.v-expansion-panel__container .v-expansion-panel__header__iconnel__header__icon {
  margin-left: auto;
}
.v-expansion-panel__container--disabled .v-expansion-panel__header {
  pointer-events: none;
}
.v-expansion-panel__container--active > .v-expansion-panel__header .v-expansion-panel__header__icon .v-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.v-expansion-panel__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding: 12px 24px;
  height: 48px;
}
.v-expansion-panel__header > *:not(.v-expansion-panel__header__icon) {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.v-expansion-panel__body {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-expansion-panel__body .v-card {
  border-radius: 0;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
}
.v-expansion-panel--popout, .v-expansion-panel--inset {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
}
.v-expansion-panel--popout .v-expansion-panel__container--active, .v-expansion-panel--inset .v-expansion-panel__container--active {
  margin: 16px;
  -webkit-box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}
.v-expansion-panel--popout .v-expansion-panel__container, .v-expansion-panel--inset .v-expansion-panel__container {
  max-width: 95%;
}
.v-expansion-panel--popout .v-expansion-panel__container--active {
  max-width: 100%;
}
.v-expansion-panel--inset .v-expansion-panel__container--active {
  max-width: 85%;
}
.theme--light .v-footer, .application .theme--light.v-footer {
  background: #f5f5f5;
  color: rgba(0,0,0,0.87);
}
.theme--dark .v-footer, .application .theme--dark.v-footer {
  background: #212121;
  color: #fff;
}
.v-footer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0 !important;
      -ms-flex: 0 1 auto !important;
          flex: 0 1 auto !important;
  min-height: 36px;
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.v-footer--absolute, .v-footer--fixed {
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}
.v-footer--inset {
  z-index: 2;
}
.v-footer--absolute {
  position: absolute;
}
.v-footer--fixed {
  position: fixed;
}
.v-form > .container {
  padding: 16px;
}
.v-form > .container > .layout > .flex {
  padding: 8px;
}
.v-form > .container > .layout:only-child {
  margin: -8px;
}
.v-form > .container > .layout:not(:only-child) {
  margin: auto -8px;
}
.container {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  margin: auto;
  padding: 24px;
  width: 100%;
}
@media only screen and (min-width: 960px) {
  .container {
    max-width: 900px;
  }
}
@media only screen and (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}
@media only screen and (min-width: 1904px) {
  .container {
    max-width: 1785px;
  }
}
@media only screen and (max-width: 959px) {
  .container {
    padding: 16px;
  }
}
.container.fluid {
  max-width: 100%;
}
.container.fill-height {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.container.fill-height > .layout {
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.container.grid-list-xs .layout .flex {
  padding: 1px;
}
.container.grid-list-xs .layout:only-child {
  margin: -1px;
}
.container.grid-list-xs .layout:not(:only-child) {
  margin: auto -1px;
}
.container.grid-list-xs *:not(:only-child) .layout:first-child {
  margin-top: -1px;
}
.container.grid-list-xs *:not(:only-child) .layout:last-child {
  margin-bottom: -1px;
}
.container.grid-list-sm .layout .flex {
  padding: 2px;
}
.container.grid-list-sm .layout:only-child {
  margin: -2px;
}
.container.grid-list-sm .layout:not(:only-child) {
  margin: auto -2px;
}
.container.grid-list-sm *:not(:only-child) .layout:first-child {
  margin-top: -2px;
}
.container.grid-list-sm *:not(:only-child) .layout:last-child {
  margin-bottom: -2px;
}
.container.grid-list-md .layout .flex {
  padding: 4px;
}
.container.grid-list-md .layout:only-child {
  margin: -4px;
}
.container.grid-list-md .layout:not(:only-child) {
  margin: auto -4px;
}
.container.grid-list-md *:not(:only-child) .layout:first-child {
  margin-top: -4px;
}
.container.grid-list-md *:not(:only-child) .layout:last-child {
  margin-bottom: -4px;
}
.container.grid-list-lg .layout .flex {
  padding: 8px;
}
.container.grid-list-lg .layout:only-child {
  margin: -8px;
}
.container.grid-list-lg .layout:not(:only-child) {
  margin: auto -8px;
}
.container.grid-list-lg *:not(:only-child) .layout:first-child {
  margin-top: -8px;
}
.container.grid-list-lg *:not(:only-child) .layout:last-child {
  margin-bottom: -8px;
}
.container.grid-list-xl .layout .flex {
  padding: 12px;
}
.container.grid-list-xl .layout:only-child {
  margin: -12px;
}
.container.grid-list-xl .layout:not(:only-child) {
  margin: auto -12px;
}
.container.grid-list-xl *:not(:only-child) .layout:first-child {
  margin-top: -12px;
}
.container.grid-list-xl *:not(:only-child) .layout:last-child {
  margin-bottom: -12px;
}
.layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  min-width: 0;
}
.layout.row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.layout.row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.layout.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.layout.column.reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.layout.column > .flex {
  max-width: 100%;
}
.layout.wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media all and (min-width: 0) {
  .flex.xs1 {
    -ms-flex-preferred-size: 8.333333333333332%;
        flex-basis: 8.333333333333332%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 8.333333333333332%;
  }
  .flex.order-xs1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .flex.xs2 {
    -ms-flex-preferred-size: 16.666666666666664%;
        flex-basis: 16.666666666666664%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 16.666666666666664%;
  }
  .flex.order-xs2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .flex.xs3 {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 25%;
  }
  .flex.order-xs3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .flex.xs4 {
    -ms-flex-preferred-size: 33.33333333333333%;
        flex-basis: 33.33333333333333%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 33.33333333333333%;
  }
  .flex.order-xs4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .flex.xs5 {
    -ms-flex-preferred-size: 41.66666666666667%;
        flex-basis: 41.66666666666667%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 41.66666666666667%;
  }
  .flex.order-xs5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .flex.xs6 {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 50%;
  }
  .flex.order-xs6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .flex.xs7 {
    -ms-flex-preferred-size: 58.333333333333336%;
        flex-basis: 58.333333333333336%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 58.333333333333336%;
  }
  .flex.order-xs7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .flex.xs8 {
    -ms-flex-preferred-size: 66.66666666666666%;
        flex-basis: 66.66666666666666%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 66.66666666666666%;
  }
  .flex.order-xs8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .flex.xs9 {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 75%;
  }
  .flex.order-xs9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .flex.xs10 {
    -ms-flex-preferred-size: 83.33333333333334%;
        flex-basis: 83.33333333333334%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 83.33333333333334%;
  }
  .flex.order-xs10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .flex.xs11 {
    -ms-flex-preferred-size: 91.66666666666666%;
        flex-basis: 91.66666666666666%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 91.66666666666666%;
  }
  .flex.order-xs11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .flex.xs12 {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 100%;
  }
  .flex.order-xs12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .flex.offset-xs0 {
    margin-left: 0%;
  }
  .flex.offset-xs1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-xs2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-xs3 {
    margin-left: 25%;
  }
  .flex.offset-xs4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-xs5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-xs6 {
    margin-left: 50%;
  }
  .flex.offset-xs7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-xs8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-xs9 {
    margin-left: 75%;
  }
  .flex.offset-xs10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-xs11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-xs12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 600px) {
  .flex.sm1 {
    -ms-flex-preferred-size: 8.333333333333332%;
        flex-basis: 8.333333333333332%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 8.333333333333332%;
  }
  .flex.order-sm1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .flex.sm2 {
    -ms-flex-preferred-size: 16.666666666666664%;
        flex-basis: 16.666666666666664%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 16.666666666666664%;
  }
  .flex.order-sm2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .flex.sm3 {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 25%;
  }
  .flex.order-sm3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .flex.sm4 {
    -ms-flex-preferred-size: 33.33333333333333%;
        flex-basis: 33.33333333333333%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 33.33333333333333%;
  }
  .flex.order-sm4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .flex.sm5 {
    -ms-flex-preferred-size: 41.66666666666667%;
        flex-basis: 41.66666666666667%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 41.66666666666667%;
  }
  .flex.order-sm5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .flex.sm6 {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 50%;
  }
  .flex.order-sm6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .flex.sm7 {
    -ms-flex-preferred-size: 58.333333333333336%;
        flex-basis: 58.333333333333336%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 58.333333333333336%;
  }
  .flex.order-sm7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .flex.sm8 {
    -ms-flex-preferred-size: 66.66666666666666%;
        flex-basis: 66.66666666666666%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 66.66666666666666%;
  }
  .flex.order-sm8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .flex.sm9 {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 75%;
  }
  .flex.order-sm9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .flex.sm10 {
    -ms-flex-preferred-size: 83.33333333333334%;
        flex-basis: 83.33333333333334%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 83.33333333333334%;
  }
  .flex.order-sm10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .flex.sm11 {
    -ms-flex-preferred-size: 91.66666666666666%;
        flex-basis: 91.66666666666666%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 91.66666666666666%;
  }
  .flex.order-sm11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .flex.sm12 {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 100%;
  }
  .flex.order-sm12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .flex.offset-sm0 {
    margin-left: 0%;
  }
  .flex.offset-sm1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-sm2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-sm3 {
    margin-left: 25%;
  }
  .flex.offset-sm4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-sm5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-sm6 {
    margin-left: 50%;
  }
  .flex.offset-sm7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-sm8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-sm9 {
    margin-left: 75%;
  }
  .flex.offset-sm10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-sm11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-sm12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 960px) {
  .flex.md1 {
    -ms-flex-preferred-size: 8.333333333333332%;
        flex-basis: 8.333333333333332%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 8.333333333333332%;
  }
  .flex.order-md1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .flex.md2 {
    -ms-flex-preferred-size: 16.666666666666664%;
        flex-basis: 16.666666666666664%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 16.666666666666664%;
  }
  .flex.order-md2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .flex.md3 {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 25%;
  }
  .flex.order-md3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .flex.md4 {
    -ms-flex-preferred-size: 33.33333333333333%;
        flex-basis: 33.33333333333333%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 33.33333333333333%;
  }
  .flex.order-md4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .flex.md5 {
    -ms-flex-preferred-size: 41.66666666666667%;
        flex-basis: 41.66666666666667%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 41.66666666666667%;
  }
  .flex.order-md5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .flex.md6 {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 50%;
  }
  .flex.order-md6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .flex.md7 {
    -ms-flex-preferred-size: 58.333333333333336%;
        flex-basis: 58.333333333333336%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 58.333333333333336%;
  }
  .flex.order-md7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .flex.md8 {
    -ms-flex-preferred-size: 66.66666666666666%;
        flex-basis: 66.66666666666666%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 66.66666666666666%;
  }
  .flex.order-md8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .flex.md9 {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 75%;
  }
  .flex.order-md9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .flex.md10 {
    -ms-flex-preferred-size: 83.33333333333334%;
        flex-basis: 83.33333333333334%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 83.33333333333334%;
  }
  .flex.order-md10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .flex.md11 {
    -ms-flex-preferred-size: 91.66666666666666%;
        flex-basis: 91.66666666666666%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 91.66666666666666%;
  }
  .flex.order-md11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .flex.md12 {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 100%;
  }
  .flex.order-md12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .flex.offset-md0 {
    margin-left: 0%;
  }
  .flex.offset-md1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-md2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-md3 {
    margin-left: 25%;
  }
  .flex.offset-md4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-md5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-md6 {
    margin-left: 50%;
  }
  .flex.offset-md7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-md8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-md9 {
    margin-left: 75%;
  }
  .flex.offset-md10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-md11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-md12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 1264px) {
  .flex.lg1 {
    -ms-flex-preferred-size: 8.333333333333332%;
        flex-basis: 8.333333333333332%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 8.333333333333332%;
  }
  .flex.order-lg1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .flex.lg2 {
    -ms-flex-preferred-size: 16.666666666666664%;
        flex-basis: 16.666666666666664%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 16.666666666666664%;
  }
  .flex.order-lg2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .flex.lg3 {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 25%;
  }
  .flex.order-lg3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .flex.lg4 {
    -ms-flex-preferred-size: 33.33333333333333%;
        flex-basis: 33.33333333333333%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 33.33333333333333%;
  }
  .flex.order-lg4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .flex.lg5 {
    -ms-flex-preferred-size: 41.66666666666667%;
        flex-basis: 41.66666666666667%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 41.66666666666667%;
  }
  .flex.order-lg5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .flex.lg6 {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 50%;
  }
  .flex.order-lg6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .flex.lg7 {
    -ms-flex-preferred-size: 58.333333333333336%;
        flex-basis: 58.333333333333336%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 58.333333333333336%;
  }
  .flex.order-lg7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .flex.lg8 {
    -ms-flex-preferred-size: 66.66666666666666%;
        flex-basis: 66.66666666666666%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 66.66666666666666%;
  }
  .flex.order-lg8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .flex.lg9 {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 75%;
  }
  .flex.order-lg9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .flex.lg10 {
    -ms-flex-preferred-size: 83.33333333333334%;
        flex-basis: 83.33333333333334%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 83.33333333333334%;
  }
  .flex.order-lg10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .flex.lg11 {
    -ms-flex-preferred-size: 91.66666666666666%;
        flex-basis: 91.66666666666666%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 91.66666666666666%;
  }
  .flex.order-lg11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .flex.lg12 {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 100%;
  }
  .flex.order-lg12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .flex.offset-lg0 {
    margin-left: 0%;
  }
  .flex.offset-lg1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-lg2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-lg3 {
    margin-left: 25%;
  }
  .flex.offset-lg4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-lg5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-lg6 {
    margin-left: 50%;
  }
  .flex.offset-lg7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-lg8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-lg9 {
    margin-left: 75%;
  }
  .flex.offset-lg10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-lg11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-lg12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 1904px) {
  .flex.xl1 {
    -ms-flex-preferred-size: 8.333333333333332%;
        flex-basis: 8.333333333333332%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 8.333333333333332%;
  }
  .flex.order-xl1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .flex.xl2 {
    -ms-flex-preferred-size: 16.666666666666664%;
        flex-basis: 16.666666666666664%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 16.666666666666664%;
  }
  .flex.order-xl2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .flex.xl3 {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 25%;
  }
  .flex.order-xl3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .flex.xl4 {
    -ms-flex-preferred-size: 33.33333333333333%;
        flex-basis: 33.33333333333333%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 33.33333333333333%;
  }
  .flex.order-xl4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .flex.xl5 {
    -ms-flex-preferred-size: 41.66666666666667%;
        flex-basis: 41.66666666666667%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 41.66666666666667%;
  }
  .flex.order-xl5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .flex.xl6 {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 50%;
  }
  .flex.order-xl6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .flex.xl7 {
    -ms-flex-preferred-size: 58.333333333333336%;
        flex-basis: 58.333333333333336%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 58.333333333333336%;
  }
  .flex.order-xl7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .flex.xl8 {
    -ms-flex-preferred-size: 66.66666666666666%;
        flex-basis: 66.66666666666666%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 66.66666666666666%;
  }
  .flex.order-xl8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .flex.xl9 {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 75%;
  }
  .flex.order-xl9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .flex.xl10 {
    -ms-flex-preferred-size: 83.33333333333334%;
        flex-basis: 83.33333333333334%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 83.33333333333334%;
  }
  .flex.order-xl10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .flex.xl11 {
    -ms-flex-preferred-size: 91.66666666666666%;
        flex-basis: 91.66666666666666%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 91.66666666666666%;
  }
  .flex.order-xl11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .flex.xl12 {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    max-width: 100%;
  }
  .flex.order-xl12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .flex.offset-xl0 {
    margin-left: 0%;
  }
  .flex.offset-xl1 {
    margin-left: 8.333333333333332%;
  }
  .flex.offset-xl2 {
    margin-left: 16.666666666666664%;
  }
  .flex.offset-xl3 {
    margin-left: 25%;
  }
  .flex.offset-xl4 {
    margin-left: 33.33333333333333%;
  }
  .flex.offset-xl5 {
    margin-left: 41.66666666666667%;
  }
  .flex.offset-xl6 {
    margin-left: 50%;
  }
  .flex.offset-xl7 {
    margin-left: 58.333333333333336%;
  }
  .flex.offset-xl8 {
    margin-left: 66.66666666666666%;
  }
  .flex.offset-xl9 {
    margin-left: 75%;
  }
  .flex.offset-xl10 {
    margin-left: 83.33333333333334%;
  }
  .flex.offset-xl11 {
    margin-left: 91.66666666666666%;
  }
  .flex.offset-xl12 {
    margin-left: 100%;
  }
}
.flex, .child-flex > * {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  max-width: 100%;
}
.align-start {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.align-end {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.align-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.align-baseline {
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.align-content-start {
  -ms-flex-line-pack: start;
      align-content: flex-start;
}
.align-content-end {
  -ms-flex-line-pack: end;
      align-content: flex-end;
}
.align-content-center {
  -ms-flex-line-pack: center;
      align-content: center;
}
.align-content-space-between {
  -ms-flex-line-pack: justify;
      align-content: space-between;
}
.align-content-space-around {
  -ms-flex-line-pack: distribute;
      align-content: space-around;
}
.justify-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.justify-space-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.justify-space-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.spacer {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
}
.grow {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
}
.shrink {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
}
.scroll-y {
  overflow-y: auto;
}
.fill-height {
  height: 100%;
}
.hide-overflow {
  overflow: hidden !important;
}
.show-overflow {
  overflow: visible !important;
}
.no-wrap {
  white-space: nowrap;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
.d-flex > *, .d-inline-flex > * {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
}
.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-inline {
  display: inline !important;
}
.theme--light .v-icon, .application .theme--light.v-icon {
  color: rgba(0,0,0,0.54);
}
.theme--light .v-icon.v-icon--disabled, .application .theme--light.v-icon.v-icon--disabled {
  color: rgba(0,0,0,0.38) !important;
}
.theme--dark .v-icon, .application .theme--dark.v-icon {
  color: #fff;
}
.theme--dark .v-icon.v-icon--disabled, .application .theme--dark.v-icon.v-icon--disabled {
  color: rgba(255,255,255,0.5) !important;
}
.v-icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
  font-size: 24px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  vertical-align: text-bottom;
}
.v-icon.v-icon.v-icon--link {
  cursor: pointer;
}
.v-icon.v-icon--large {
  font-size: 2.5rem;
}
.v-icon.v-icon--medium {
  font-size: 2rem;
}
.v-icon.v-icon--x-large {
  font-size: 3rem;
}
.v-icon.v-icon--disabled {
  pointer-events: none;
}
.theme--light .v-input:not(.v-input--is-disabled) input, .application .theme--light.v-input:not(.v-input--is-disabled) input, .theme--light .v-input:not(.v-input--is-disabled) textarea, .application .theme--light.v-input:not(.v-input--is-disabled) textarea {
  color: rgba(0,0,0,0.87);
}
.theme--light .v-input input::-webkit-input-placeholder, .application .theme--light.v-input input::-webkit-input-placeholder, .theme--light .v-input textarea::-webkit-input-placeholder, .application .theme--light.v-input textarea::-webkit-input-placeholder {
  color: rgba(0,0,0,0.38);
}
.theme--light .v-input input:-ms-input-placeholder, .application .theme--light.v-input input:-ms-input-placeholder, .theme--light .v-input textarea:-ms-input-placeholder, .application .theme--light.v-input textarea:-ms-input-placeholder {
  color: rgba(0,0,0,0.38);
}
.theme--light .v-input input::-ms-input-placeholder, .application .theme--light.v-input input::-ms-input-placeholder, .theme--light .v-input textarea::-ms-input-placeholder, .application .theme--light.v-input textarea::-ms-input-placeholder {
  color: rgba(0,0,0,0.38);
}
.theme--light .v-input input::placeholder, .application .theme--light.v-input input::placeholder, .theme--light .v-input textarea::placeholder, .application .theme--light.v-input textarea::placeholder {
  color: rgba(0,0,0,0.38);
}
.theme--light .v-input--is-disabled .v-label, .application .theme--light.v-input--is-disabled .v-label, .theme--light .v-input--is-disabled input, .application .theme--light.v-input--is-disabled input, .theme--light .v-input--is-disabled textarea, .application .theme--light.v-input--is-disabled textarea {
  color: rgba(0,0,0,0.38);
}
.theme--dark .v-input:not(.v-input--is-disabled) input, .application .theme--dark.v-input:not(.v-input--is-disabled) input, .theme--dark .v-input:not(.v-input--is-disabled) textarea, .application .theme--dark.v-input:not(.v-input--is-disabled) textarea {
  color: #fff;
}
.theme--dark .v-input input::-webkit-input-placeholder, .application .theme--dark.v-input input::-webkit-input-placeholder, .theme--dark .v-input textarea::-webkit-input-placeholder, .application .theme--dark.v-input textarea::-webkit-input-placeholder {
  color: rgba(255,255,255,0.5);
}
.theme--dark .v-input input:-ms-input-placeholder, .application .theme--dark.v-input input:-ms-input-placeholder, .theme--dark .v-input textarea:-ms-input-placeholder, .application .theme--dark.v-input textarea:-ms-input-placeholder {
  color: rgba(255,255,255,0.5);
}
.theme--dark .v-input input::-ms-input-placeholder, .application .theme--dark.v-input input::-ms-input-placeholder, .theme--dark .v-input textarea::-ms-input-placeholder, .application .theme--dark.v-input textarea::-ms-input-placeholder {
  color: rgba(255,255,255,0.5);
}
.theme--dark .v-input input::placeholder, .application .theme--dark.v-input input::placeholder, .theme--dark .v-input textarea::placeholder, .application .theme--dark.v-input textarea::placeholder {
  color: rgba(255,255,255,0.5);
}
.theme--dark .v-input--is-disabled .v-label, .application .theme--dark.v-input--is-disabled .v-label, .theme--dark .v-input--is-disabled input, .application .theme--dark.v-input--is-disabled input, .theme--dark .v-input--is-disabled textarea, .application .theme--dark.v-input--is-disabled textarea {
  color: rgba(255,255,255,0.5);
}
.v-input {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  font-size: 16px;
  margin-top: 16px;
  text-align: left;
}
.v-input .v-progress-linear {
  top: calc(100% - 1px);
  left: 0;
  margin: 0;
  position: absolute;
}
.v-input input {
  max-height: 32px;
}
.v-input input:invalid, .v-input textarea:invalid {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.v-input input:focus, .v-input textarea:focus, .v-input input:active, .v-input textarea:active {
  outline: none;
}
.v-input .v-label {
  height: 20px;
  line-height: 20px;
}
.v-input__append-outer, .v-input__prepend-outer {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 4px;
  margin-top: 4px;
  line-height: 1;
}
.v-input__append-outer .v-icon, .v-input__prepend-outer .v-icon {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-input__append-outer {
  margin-left: 9px;
}
.v-input__prepend-outer {
  margin-right: 9px;
}
.v-input__control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: auto;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.v-input__icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 24px;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-width: 24px;
  width: 24px;
}
.v-input__icon--clear {
  border-radius: 50%;
}
.v-input__slot {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-input--is-disabled:not(.v-input--is-readonly) {
  pointer-events: none;
}
.v-input--is-loading .v-input__slot:before, .v-input--is-loading .v-input__slot:after {
  display: none;
}
.v-input--hide-details .v-input__slot {
  margin-bottom: 0;
}
.v-input--has-state.error--text .v-label {
  -webkit-animation: shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
          animation: shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.theme--light .v-jumbotron__content, .application .theme--light.v-jumbotron__content {
  color: rgba(0,0,0,0.87);
}
.theme--dark .v-jumbotron__content, .application .theme--dark.v-jumbotron__content {
  color: #fff;
}
.v-jumbotron {
  display: block;
  top: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-jumbotron__wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
  -webkit-transition: inherit;
  transition: inherit;
  width: 100%;
}
.v-jumbotron__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  contain: strict;
  -webkit-transition: inherit;
  transition: inherit;
}
.v-jumbotron__image {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  min-width: 100%;
  will-change: transform;
  -webkit-transition: inherit;
  transition: inherit;
}
.v-jumbotron__content {
  height: 100%;
  position: relative;
  -webkit-transition: inherit;
  transition: inherit;
}
.theme--light .v-label, .application .theme--light.v-label {
  color: rgba(0,0,0,0.54);
}
.theme--light .v-label--is-disabled, .application .theme--light.v-label--is-disabled {
  color: rgba(0,0,0,0.38);
}
.theme--dark .v-label, .application .theme--dark.v-label {
  color: rgba(255,255,255,0.7);
}
.theme--dark .v-label--is-disabled, .application .theme--dark.v-label--is-disabled {
  color: rgba(255,255,255,0.5);
}
.v-label {
  font-size: 16px;
  line-height: 1;
  min-height: 8px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.theme--light .v-list, .application .theme--light.v-list {
  background: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--light .v-list .v-list--disabled, .application .theme--light.v-list .v-list--disabled {
  color: rgba(0,0,0,0.38);
}
.theme--light .v-list .v-list__tile__sub-title, .application .theme--light.v-list .v-list__tile__sub-title {
  color: rgba(0,0,0,0.54);
}
.theme--light .v-list .v-list__tile__mask, .application .theme--light.v-list .v-list__tile__mask {
  color: rgba(0,0,0,0.38);
  background: #eee;
}
.theme--light .v-list .v-list__tile--link:hover, .application .theme--light.v-list .v-list__tile--link:hover, .theme--light .v-list .v-list__tile--highlighted, .application .theme--light.v-list .v-list__tile--highlighted, .theme--light .v-list .v-list__group__header:hover, .application .theme--light.v-list .v-list__group__header:hover {
  background: rgba(0,0,0,0.04);
}
.theme--light .v-list .v-list__group--active:before, .application .theme--light.v-list .v-list__group--active:before, .theme--light .v-list .v-list__group--active:after, .application .theme--light.v-list .v-list__group--active:after {
  background: rgba(0,0,0,0.12);
}
.theme--light .v-list .v-list__group--disabled .v-list__tile, .application .theme--light.v-list .v-list__group--disabled .v-list__tile {
  color: rgba(0,0,0,0.38) !important;
}
.theme--light .v-list .v-list__group--disabled .v-list__group__header__prepend-icon .v-icon, .application .theme--light.v-list .v-list__group--disabled .v-list__group__header__prepend-icon .v-icon {
  color: rgba(0,0,0,0.38) !important;
}
.theme--dark .v-list, .application .theme--dark.v-list {
  background: #424242;
  color: #fff;
}
.theme--dark .v-list .v-list--disabled, .application .theme--dark.v-list .v-list--disabled {
  color: rgba(255,255,255,0.5);
}
.theme--dark .v-list .v-list__tile__sub-title, .application .theme--dark.v-list .v-list__tile__sub-title {
  color: rgba(255,255,255,0.7);
}
.theme--dark .v-list .v-list__tile__mask, .application .theme--dark.v-list .v-list__tile__mask {
  color: rgba(255,255,255,0.5);
  background: #494949;
}
.theme--dark .v-list .v-list__tile--link:hover, .application .theme--dark.v-list .v-list__tile--link:hover, .theme--dark .v-list .v-list__tile--highlighted, .application .theme--dark.v-list .v-list__tile--highlighted, .theme--dark .v-list .v-list__group__header:hover, .application .theme--dark.v-list .v-list__group__header:hover {
  background: rgba(255,255,255,0.08);
}
.theme--dark .v-list .v-list__group--active:before, .application .theme--dark.v-list .v-list__group--active:before, .theme--dark .v-list .v-list__group--active:after, .application .theme--dark.v-list .v-list__group--active:after {
  background: rgba(255,255,255,0.12);
}
.theme--dark .v-list .v-list__group--disabled .v-list__tile, .application .theme--dark.v-list .v-list__group--disabled .v-list__tile {
  color: rgba(255,255,255,0.5) !important;
}
.theme--dark .v-list .v-list__group--disabled .v-list__group__header__prepend-icon .v-icon, .application .theme--dark.v-list .v-list__group--disabled .v-list__group__header__prepend-icon .v-icon {
  color: rgba(255,255,255,0.5) !important;
}
.v-list {
  list-style-type: none;
  padding: 8px 0 8px;
  -webkit-transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.v-list .v-input .v-messages {
  display: none;
}
.v-list .v-input, .v-list .v-input__slot {
  margin: 0;
}
.v-list__tile {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: 48px;
  margin: 0;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-list__tile--link {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-list__tile__content, .v-list__tile__action {
  height: 100%;
}
.v-list__tile__title, .v-list__tile__sub-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-list__tile__title {
  height: 24px;
  line-height: 24px;
  position: relative;
  text-align: left;
}
.v-list__tile__sub-title {
  font-size: 14px;
}
.v-list__tile__avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  min-width: 56px;
}
.v-list__tile__action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  min-width: 56px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.v-list__tile__action .v-btn {
  padding: 0;
  margin: 0;
}
.v-list__tile__action .v-btn--icon {
  margin: -6px;
}
.v-list__tile__action .v-radio.v-radio {
  margin: 0;
}
.v-list__tile__action-text {
  color: #9e9e9e;
  font-size: 12px;
}
.v-list__tile__action--stack {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.v-list__tile__content {
  text-align: left;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.v-list__tile__content ~ .v-list__tile__avatar {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.v-list__tile__content ~ .v-list__tile__action:not(.v-list__tile__action--stack) {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.v-list__tile--active .v-list__tile__action:first-of-type .v-icon {
  color: inherit;
}
.v-list__tile--avatar {
  height: 56px;
}
.v-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
}
.v-list--dense .v-subheader {
  font-size: 13px;
  height: 40px;
}
.v-list--dense .v-list__group .v-subheader {
  height: 40px;
}
.v-list--dense .v-list__tile {
  font-size: 13px;
}
.v-list--dense .v-list__tile--avatar {
  height: 48px;
}
.v-list--dense .v-list__tile:not(.v-list__tile--avatar) {
  height: 40px;
}
.v-list--dense .v-list__tile .v-icon {
  font-size: 22px;
}
.v-list--dense .v-list__tile__sub-title {
  font-size: 13px;
}
.v-list--disabled {
  pointer-events: none;
}
.v-list--two-line .v-list__tile {
  height: 72px;
}
.v-list--two-line.v-list--dense .v-list__tile {
  height: 60px;
}
.v-list--three-line .v-list__tile {
  height: 88px;
}
.v-list--three-line .v-list__tile__avatar {
  margin-top: -18px;
}
.v-list--three-line .v-list__tile__sub-title {
  white-space: initial;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.v-list--three-line.v-list--dense .v-list__tile {
  height: 76px;
}
.v-list > .v-list__group:before {
  top: 0;
}
.v-list > .v-list__group:before .v-list__tile__avatar {
  margin-top: -14px;
}
.v-list__group {
  padding: 0;
  position: relative;
  -webkit-transition: inherit;
  transition: inherit;
}
.v-list__group:before, .v-list__group:after {
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-list__group--active ~ .v-list__group:before {
  display: none;
}
.v-list__group__header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style-type: none;
}
.v-list__group__header > div:not(.v-list__group__header__prepend-icon):not(.v-list__group__header__append-icon) {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}
.v-list__group__header .v-list__group__header__append-icon, .v-list__group__header .v-list__group__header__prepend-icon {
  padding: 0 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-list__group__header--sub-group {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.v-list__group__header--sub-group div .v-list__tile {
  padding-left: 0;
}
.v-list__group__header--sub-group .v-list__group__header__prepend-icon {
  padding: 0 0 0 40px;
  margin-right: 8px;
}
.v-list__group__header .v-list__group__header__prepend-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  min-width: 56px;
}
.v-list__group__header--active .v-list__group__header__append-icon .v-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.v-list__group__header--active .v-list__group__header__prepend-icon .v-icon {
  color: inherit;
}
.v-list__group__header--active.v-list__group__header--sub-group .v-list__group__header__prepend-icon .v-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.v-list__group__items {
  position: relative;
  padding: 0;
  -webkit-transition: inherit;
  transition: inherit;
}
.v-list__group__items > div {
  display: block;
}
.v-list__group__items--no-action .v-list__tile {
  padding-left: 72px;
}
.v-list__group--disabled {
  pointer-events: none;
}
.v-list--subheader {
  padding-top: 0;
}
.v-menu {
  display: block;
  position: relative;
  vertical-align: middle;
}
.v-menu--inline {
  display: inline-block;
}
.v-menu__activator {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  position: relative;
}
.v-menu__activator * {
  cursor: pointer;
}
.v-menu__activator--disabled {
  pointer-events: none;
}
.v-menu__content {
  position: absolute;
  display: inline-block;
  border-radius: 2px;
  max-width: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  will-change: transform;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
          box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
.v-menu__content--active {
  pointer-events: none;
}
.v-menu__content > .card {
  contain: content;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.v-menu > .v-menu__content {
  max-width: none;
}
.v-menu-transition-enter .v-list__tile {
  min-width: 0;
  pointer-events: none;
}
.v-menu-transition-enter-to .v-list__tile {
  pointer-events: auto;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.v-menu-transition-leave-active, .v-menu-transition-leave-to {
  pointer-events: none;
}
.v-menu-transition-enter, .v-menu-transition-leave-to {
  opacity: 0;
}
.v-menu-transition-enter-active, .v-menu-transition-leave-active {
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.v-menu-transition-enter.v-menu__content--auto .v-list__tile {
  opacity: 0;
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
}
.v-menu-transition-enter.v-menu__content--auto .v-list__tile--active {
  opacity: 1;
  -webkit-transform: none !important;
          transform: none !important;
  pointer-events: auto;
}
.theme--light .v-messages, .application .theme--light.v-messages {
  color: rgba(0,0,0,0.54);
}
.theme--dark .v-messages, .application .theme--dark.v-messages {
  color: rgba(255,255,255,0.7);
}
.application--is-rtl .v-messages {
  text-align: right;
}
.v-messages {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  font-size: 12px;
  min-height: 12px;
  min-width: 1px;
  position: relative;
}
.v-messages__message {
  line-height: 1;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.theme--light .v-navigation-drawer, .application .theme--light.v-navigation-drawer {
  background-color: #fff;
}
.theme--light .v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border, .application .theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  background-color: rgba(0,0,0,0.12);
}
.theme--light .v-navigation-drawer .v-divider, .application .theme--light.v-navigation-drawer .v-divider {
  border-color: rgba(0,0,0,0.12);
}
.theme--dark .v-navigation-drawer, .application .theme--dark.v-navigation-drawer {
  background-color: #424242;
}
.theme--dark .v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border, .application .theme--dark.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  background-color: rgba(255,255,255,0.12);
}
.theme--dark .v-navigation-drawer .v-divider, .application .theme--dark.v-navigation-drawer .v-divider {
  border-color: rgba(255,255,255,0.12);
}
.v-navigation-drawer {
  -webkit-transition: none;
  transition: none;
  display: block;
  left: 0;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: auto;
  top: 0;
  will-change: transform;
  z-index: 3;
  -webkit-overflow-scrolling: touch;
}
.v-navigation-drawer[data-booted="true"] {
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.v-navigation-drawer[data-booted="true"] {
  -webkit-transition-property: width, -webkit-transform;
  transition-property: width, -webkit-transform;
  transition-property: transform, width;
  transition-property: transform, width, -webkit-transform;
}
.v-navigation-drawer__border {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
}
.v-navigation-drawer.v-navigation-drawer--right:after {
  left: 0;
  right: initial;
}
.v-navigation-drawer--right {
  left: auto;
  right: 0;
}
.v-navigation-drawer--right > .v-navigation-drawer__border {
  right: auto;
  left: 0;
}
.v-navigation-drawer--absolute {
  position: absolute;
}
.v-navigation-drawer--fixed {
  position: fixed;
}
.v-navigation-drawer--floating:after {
  display: none;
}
.v-navigation-drawer--mini-variant {
  overflow: hidden;
}
.v-navigation-drawer--mini-variant .v-list__group__header__prepend-icon {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}
.v-navigation-drawer--mini-variant .v-list__tile__action, .v-navigation-drawer--mini-variant .v-list__tile__avatar {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-width: 48px;
}
.v-navigation-drawer--mini-variant .v-list__tile__content, .v-navigation-drawer--mini-variant .v-list__tile:after {
  opacity: 0;
}
.v-navigation-drawer--mini-variant .v-subheader, .v-navigation-drawer--mini-variant .v-divider, .v-navigation-drawer--mini-variant .v-list--group {
  display: none !important;
}
.v-navigation-drawer--temporary, .v-navigation-drawer--is-mobile {
  z-index: 6;
}
.v-navigation-drawer--temporary:not(.v-navigation-drawer--close), .v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close) {
  -webkit-box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12);
          box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12);
}
.v-navigation-drawer .v-list {
  background: inherit;
}
.v-navigation-drawer > .v-list .v-list__tile {
  -webkit-transition: none;
  transition: none;
  font-weight: 500;
}
.v-navigation-drawer > .v-list .v-list__tile--active .v-list__tile__title {
  color: inherit;
}
.v-navigation-drawer > .v-list .v-list--group .v-list__tile {
  font-weight: 400;
}
.v-navigation-drawer > .v-list .v-list--group__header--active:after {
  background: transparent;
}
.v-navigation-drawer > .v-list:not(.v-list--dense) .v-list__tile {
  font-size: 14px;
}
.theme--light .v-overflow-btn .v-input__control::before, .application .theme--light.v-overflow-btn .v-input__control::before, .theme--light .v-overflow-btn .v-input__slot::before, .application .theme--light.v-overflow-btn .v-input__slot::before {
  background-color: rgba(0,0,0,0.12) !important;
}
.theme--light .v-overflow-btn--segmented .v-input__append-inner, .application .theme--light.v-overflow-btn--segmented .v-input__append-inner, .theme--light .v-overflow-btn--editable:hover .v-input__append-inner, .application .theme--light.v-overflow-btn--editable:hover .v-input__append-inner, .theme--light .v-overflow-btn--editable.v-input--is-focused .v-input__append-inner, .application .theme--light.v-overflow-btn--editable.v-input--is-focused .v-input__append-inner, .theme--light .v-overflow-btn--editable.v-select--is-menu-active .v-input__append-inner, .application .theme--light.v-overflow-btn--editable.v-select--is-menu-active .v-input__append-inner {
  border-left: 1px solid rgba(0,0,0,0.12);
}
.theme--light .v-overflow-btn:hover .v-input__slot, .application .theme--light.v-overflow-btn:hover .v-input__slot, .theme--light .v-overflow-btn.v-input--is-focused .v-input__slot, .application .theme--light.v-overflow-btn.v-input--is-focused .v-input__slot, .theme--light .v-overflow-btn.v-select--is-menu-active .v-input__slot, .application .theme--light.v-overflow-btn.v-select--is-menu-active .v-input__slot {
  background: #fff;
}
.theme--dark .v-overflow-btn .v-input__control::before, .application .theme--dark.v-overflow-btn .v-input__control::before, .theme--dark .v-overflow-btn .v-input__slot::before, .application .theme--dark.v-overflow-btn .v-input__slot::before {
  background-color: rgba(255,255,255,0.12) !important;
}
.theme--dark .v-overflow-btn--segmented .v-input__append-inner, .application .theme--dark.v-overflow-btn--segmented .v-input__append-inner, .theme--dark .v-overflow-btn--editable:hover .v-input__append-inner, .application .theme--dark.v-overflow-btn--editable:hover .v-input__append-inner, .theme--dark .v-overflow-btn--editable.v-input--is-focused .v-input__append-inner, .application .theme--dark.v-overflow-btn--editable.v-input--is-focused .v-input__append-inner, .theme--dark .v-overflow-btn--editable.v-select--is-menu-active .v-input__append-inner, .application .theme--dark.v-overflow-btn--editable.v-select--is-menu-active .v-input__append-inner {
  border-left: 1px solid rgba(255,255,255,0.12);
}
.theme--dark .v-overflow-btn:hover .v-input__slot, .application .theme--dark.v-overflow-btn:hover .v-input__slot, .theme--dark .v-overflow-btn.v-input--is-focused .v-input__slot, .application .theme--dark.v-overflow-btn.v-input--is-focused .v-input__slot, .theme--dark .v-overflow-btn.v-select--is-menu-active .v-input__slot, .application .theme--dark.v-overflow-btn.v-select--is-menu-active .v-input__slot {
  background: #424242;
}
.v-overflow-btn {
  margin-top: 12px;
}
.v-overflow-btn:not(.v-overflow-btn--editable) .v-menu__activator, .v-overflow-btn:not(.v-overflow-btn--editable) .v-menu__activator * {
  cursor: pointer;
}
.v-overflow-btn .v-select__slot {
  height: 48px;
}
.v-overflow-btn .v-select__slot input {
  margin-left: 16px;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 16px;
}
.v-overflow-btn .v-input__slot {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-overflow-btn .v-input__slot::after {
  content: none;
}
.v-overflow-btn .v-label {
  margin-left: 16px;
  top: calc(50% - 10px);
}
.v-overflow-btn .v-input__append-inner {
  width: 48px;
  height: 48px;
  -ms-flex-item-align: auto;
      align-self: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 0;
  padding: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.v-overflow-btn .v-input__append-outer, .v-overflow-btn .v-input__prepend-outer {
  margin-top: 12px;
  margin-bottom: 12px;
}
.v-overflow-btn .v-input__control::before {
  height: 1px;
  top: -1px;
  content: '';
  left: 0;
  position: absolute;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-overflow-btn.v-input--is-focused .v-input__slot, .v-overflow-btn.v-select--is-menu-active .v-input__slot {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.v-overflow-btn .v-select__selections {
  width: 0px;
}
.v-overflow-btn--segmented .v-select__selections {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
.v-overflow-btn--segmented .v-select__selections .v-btn {
  border-radius: 0;
  margin: 0;
  margin-right: -16px;
  height: 48px;
  width: 100%;
}
.v-overflow-btn--segmented .v-select__selections .v-btn__content {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}
.v-overflow-btn--segmented .v-select__selections .v-btn__content::before {
  background-color: transparent;
}
.v-overflow-btn--editable .v-input__append-inner, .v-overflow-btn--editable .v-input__append-inner * {
  cursor: pointer;
}
.v-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  z-index: 5;
}
.v-overlay--absolute {
  position: absolute;
}
.v-overlay:before {
  background-color: #212121;
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: inherit;
  transition: inherit;
  -webkit-transition-delay: 150ms;
          transition-delay: 150ms;
  width: 100%;
}
.v-overlay--active {
  pointer-events: auto;
  -ms-touch-action: none;
      touch-action: none;
}
.v-overlay--active:before {
  opacity: 0.46;
}
.theme--light .v-pagination__item, .application .theme--light.v-pagination__item {
  background: #fff;
  color: #000;
}
.theme--light .v-pagination__item--active, .application .theme--light.v-pagination__item--active {
  color: #fff;
}
.theme--light .v-pagination__navigation, .application .theme--light.v-pagination__navigation {
  background: #fff;
}
.theme--light .v-pagination__navigation .v-icon, .application .theme--light.v-pagination__navigation .v-icon {
  color: rgba(0,0,0,0.54);
}
.theme--dark .v-pagination__item, .application .theme--dark.v-pagination__item {
  background: #424242;
  color: #fff;
}
.theme--dark .v-pagination__item--active, .application .theme--dark.v-pagination__item--active {
  color: #fff;
}
.theme--dark .v-pagination__navigation, .application .theme--dark.v-pagination__navigation {
  background: #424242;
}
.theme--dark .v-pagination__navigation .v-icon, .application .theme--dark.v-pagination__navigation .v-icon {
  color: #fff;
}
.v-pagination {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  list-style-type: none;
  margin: 0;
  max-width: 100%;
  padding: 0;
}
.v-pagination > li {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.v-pagination--circle .v-pagination__item, .v-pagination--circle .v-pagination__more, .v-pagination--circle .v-pagination__navigation {
  border-radius: 50%;
}
.v-pagination--disabled {
  pointer-events: none;
  opacity: 0.6;
}
.v-pagination__item {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  background: transparent;
  height: 34px;
  width: 34px;
  margin: 0.3rem;
  text-decoration: none;
  -webkit-transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.v-pagination__item--active {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
.v-pagination__navigation {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  height: 2rem;
  border-radius: 4px;
  width: 2rem;
  margin: 0.3rem 10px;
}
.v-pagination__navigation .v-icon {
  font-size: 2rem;
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  vertical-align: middle;
}
.v-pagination__navigation--disabled {
  opacity: 0.6;
  pointer-events: none;
}
.v-pagination__more {
  margin: 0.3rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 2rem;
  width: 2rem;
}
.v-parallax {
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.v-parallax__image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  contain: strict;
}
.v-parallax__image {
  position: absolute;
  bottom: 0;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  display: none;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  will-change: transform;
  -webkit-transition: 0.3s opacity cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s opacity cubic-bezier(0.25, 0.8, 0.5, 1);
  z-index: 1;
}
.v-parallax__content {
  color: #fff;
  height: 100%;
  z-index: 2;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 1rem;
}
.theme--light .v-picker__title, .application .theme--light.v-picker__title {
  background: #e0e0e0;
}
.theme--dark .v-picker__title, .application .theme--dark.v-picker__title {
  background: #616161;
}
.theme--light .v-picker__body, .application .theme--light.v-picker__body {
  background: #fff;
}
.theme--dark .v-picker__body, .application .theme--dark.v-picker__body {
  background: #424242;
}
.v-picker {
  border-radius: 2px;
  contain: layout style;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  vertical-align: top;
}
.v-picker--full-width {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.v-picker__title {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 16px;
}
.v-picker__title__btn {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-picker__title__btn.active {
  opacity: 1;
}
.v-picker__title__btn:not(.active) {
  opacity: 0.6;
  cursor: pointer;
}
.v-picker__title__btn:not(.active):hover {
  opacity: 1;
}
.v-picker__body {
  height: auto;
  overflow: hidden;
  position: relative;
  z-index: 0;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.v-picker__body > div {
  width: 100%;
}
.v-picker__body > div.fade-transition-leave-active {
  position: absolute;
}
.v-picker--landscape .v-picker__title {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 170px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
}
.v-picker--landscape .v-picker__body, .v-picker--landscape .v-picker__actions {
  margin-left: 170px;
}
.v-progress-circular {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.v-progress-circular svg {
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}
.v-progress-circular--indeterminate svg {
  -webkit-animation: progress-circular-rotate 1.4s linear infinite;
          animation: progress-circular-rotate 1.4s linear infinite;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.v-progress-circular--indeterminate .v-progress-circular__overlay {
  -webkit-animation: progress-circular-dash 1.4s ease-in-out infinite;
          animation: progress-circular-dash 1.4s ease-in-out infinite;
  stroke-linecap: round;
  stroke-dasharray: 80, 200;
  stroke-dashoffset: 0px;
}
.v-progress-circular__underlay {
  stroke: rgba(0,0,0,0.1);
  z-index: 1;
}
.v-progress-circular__overlay {
  stroke: currentColor;
  z-index: 2;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.v-progress-circular__info {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
@-webkit-keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}
@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}
@-webkit-keyframes progress-circular-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes progress-circular-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.v-progress-linear {
  background: transparent;
  margin: 1rem 0;
  overflow: hidden;
  width: 100%;
  position: relative;
}
.v-progress-linear__bar {
  width: 100%;
  height: inherit;
  position: relative;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  z-index: 1;
}
.v-progress-linear__bar__determinate {
  height: inherit;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.v-progress-linear__bar__indeterminate .long, .v-progress-linear__bar__indeterminate .short {
  height: inherit;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  will-change: left, right;
  width: auto;
  background-color: inherit;
}
.v-progress-linear__bar__indeterminate--active .long {
  -webkit-animation: indeterminate;
          animation: indeterminate;
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.v-progress-linear__bar__indeterminate--active .short {
  -webkit-animation: indeterminate-short;
          animation: indeterminate-short;
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.v-progress-linear__background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.v-progress-linear--query .v-progress-linear__bar__indeterminate--active .long {
  -webkit-animation: query;
          animation: query;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.v-progress-linear--query .v-progress-linear__bar__indeterminate--active .short {
  -webkit-animation: query-short;
          animation: query-short;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@-webkit-keyframes indeterminate {
  0% {
    left: -90%;
    right: 100%;
  }
  60% {
    left: -90%;
    right: 100%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}
@keyframes indeterminate {
  0% {
    left: -90%;
    right: 100%;
  }
  60% {
    left: -90%;
    right: 100%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@-webkit-keyframes query {
  0% {
    right: -90%;
    left: 100%;
  }
  60% {
    right: -90%;
    left: 100%;
  }
  100% {
    right: 100%;
    left: -35%;
  }
}
@keyframes query {
  0% {
    right: -90%;
    left: 100%;
  }
  60% {
    right: -90%;
    left: 100%;
  }
  100% {
    right: 100%;
    left: -35%;
  }
}
@-webkit-keyframes query-short {
  0% {
    right: -200%;
    left: 100%;
  }
  60% {
    right: 107%;
    left: -8%;
  }
  100% {
    right: 107%;
    left: -8%;
  }
}
@keyframes query-short {
  0% {
    right: -200%;
    left: 100%;
  }
  60% {
    right: 107%;
    left: -8%;
  }
  100% {
    right: 107%;
    left: -8%;
  }
}
.v-input--radio-group .v-radio {
  margin-bottom: 8px;
}
.v-input--radio-group__input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.v-input--radio-group__input > .v-label {
  padding-bottom: 8px;
}
.v-input--radio-group--row .v-input--radio-group__input {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.v-input--radio-group--column .v-input--radio-group__input {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.theme--light .v-radio--is-disabled label, .application .theme--light.v-radio--is-disabled label {
  color: rgba(0,0,0,0.38);
}
.theme--light .v-radio--is-disabled .v-icon, .application .theme--light.v-radio--is-disabled .v-icon {
  color: rgba(0,0,0,0.26) !important;
}
.theme--dark .v-radio--is-disabled label, .application .theme--dark.v-radio--is-disabled label {
  color: rgba(255,255,255,0.5);
}
.theme--dark .v-radio--is-disabled .v-icon, .application .theme--dark.v-radio--is-disabled .v-icon {
  color: rgba(255,255,255,0.3) !important;
}
.v-radio {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-right: 16px;
  outline: none;
}
.v-radio--is-disabled {
  pointer-events: none;
}
.theme--light .v-input--range-slider.v-input--slider.v-input--is-disabled .v-slider.v-slider .v-slider__thumb, .application .theme--light.v-input--range-slider.v-input--slider.v-input--is-disabled .v-slider.v-slider .v-slider__thumb {
  background: #bdbdbd;
}
.theme--dark .v-input--range-slider.v-input--slider.v-input--is-disabled .v-slider.v-slider .v-slider__thumb, .application .theme--dark.v-input--range-slider.v-input--slider.v-input--is-disabled .v-slider.v-slider .v-slider__thumb {
  background: #424242;
}
.v-input--range-slider.v-input--is-disabled .v-slider__track-fill {
  display: none;
}
.v-input--range-slider.v-input--is-disabled.v-input--slider .v-slider.v-slider .v-slider__thumb {
  border-color: transparent;
}
.v-ripple__container {
  color: inherit;
  border-radius: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
  contain: strict;
}
.v-ripple__animation {
  color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: currentColor;
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  pointer-events: none;
  overflow: hidden;
  will-change: transform, opacity;
}
.v-ripple__animation--enter {
  -webkit-transition: none;
  transition: none;
}
.v-ripple__animation--visible {
  opacity: 0.15;
}
.theme--light .v-select__selections, .application .theme--light.v-select__selections {
  color: rgba(0,0,0,0.87);
}
.theme--light .v-select.v-input--is-disabled .v-select__selections, .application .theme--light.v-select.v-input--is-disabled .v-select__selections {
  color: rgba(0,0,0,0.38);
}
.theme--light .v-select .v-chip--disabled, .application .theme--light.v-select .v-chip--disabled, .theme--light .v-select .v-select__selection--disabled, .application .theme--light.v-select .v-select__selection--disabled {
  color: rgba(0,0,0,0.38);
}
.theme--light .v-select.v-text-field--solo-inverted.v-input--is-focused .v-select__selections, .application .theme--light.v-select.v-text-field--solo-inverted.v-input--is-focused .v-select__selections {
  color: #fff;
}
.theme--dark .v-select__selections, .application .theme--dark.v-select__selections {
  color: #fff;
}
.theme--dark .v-select.v-input--is-disabled .v-select__selections, .application .theme--dark.v-select.v-input--is-disabled .v-select__selections {
  color: rgba(255,255,255,0.5);
}
.theme--dark .v-select .v-chip--disabled, .application .theme--dark.v-select .v-chip--disabled, .theme--dark .v-select .v-select__selection--disabled, .application .theme--dark.v-select .v-select__selection--disabled {
  color: rgba(255,255,255,0.5);
}
.theme--dark .v-select.v-text-field--solo-inverted.v-input--is-focused .v-select__selections, .application .theme--dark.v-select.v-text-field--solo-inverted.v-input--is-focused .v-select__selections {
  color: rgba(0,0,0,0.87);
}
.v-select {
  cursor: pointer;
  position: relative;
}
.v-select .v-menu {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 100%;
}
.v-select .v-chip {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
}
.v-select .fade-transition-leave-active {
  position: absolute;
  left: 0;
}
.v-select.v-input--is-dirty ::-webkit-input-placeholder {
  color: transparent !important;
}
.v-select.v-input--is-dirty :-ms-input-placeholder {
  color: transparent !important;
}
.v-select.v-input--is-dirty ::-ms-input-placeholder {
  color: transparent !important;
}
.v-select.v-input--is-dirty ::placeholder {
  color: transparent !important;
}
.v-select.v-text-field--enclosed:not(.v-text-field--single-line) .v-select__selections {
  padding-top: 24px;
}
.v-select.v-text-field input {
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  margin-top: 0;
  min-width: 0;
  pointer-events: none;
  position: relative;
}
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.v-select.v-select--chips input {
  margin: 0;
}
.v-select.v-select--chips .v-select__selections {
  min-height: 42px;
}
.v-select.v-select--chips.v-select--chips--small .v-select__selections {
  min-height: 32px;
}
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box .v-select__selections, .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections {
  min-height: 68px;
}
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box.v-select--chips--small .v-select__selections, .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-select--chips--small .v-select__selections {
  min-height: 56px;
}
.v-select.v-text-field--reverse .v-select__slot, .v-select.v-text-field--reverse .v-select__selections {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.v-select__selections {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  line-height: 18px;
}
.v-select__selection {
  max-width: 90%;
}
.v-select__selection--comma {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 7px 4px 7px 0;
}
.v-select__slot {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
}
.v-select:not(.v-text-field--single-line) .v-select__slot > input {
  -ms-flex-item-align: end;
      align-self: flex-end;
}
.theme--light .v-input--selection-controls.v-input--is-disabled .v-icon, .application .theme--light.v-input--selection-controls.v-input--is-disabled .v-icon {
  color: rgba(0,0,0,0.26) !important;
}
.theme--dark .v-input--selection-controls.v-input--is-disabled .v-icon, .application .theme--dark.v-input--selection-controls.v-input--is-disabled .v-icon {
  color: rgba(255,255,255,0.3) !important;
}
.application--is-rtl .v-input--selection-controls .v-input--selection-controls__input {
  margin-right: 0;
  margin-left: 8px;
}
.v-input--selection-controls {
  padding: 4px 0;
}
.v-input--selection-controls .v-input__append-outer, .v-input--selection-controls .v-input__prepend-outer {
  margin-top: 0;
  margin-bottom: 0;
}
.v-input--selection-controls__input {
  color: inherit;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  height: 24px;
  position: relative;
  margin-right: 8px;
  -webkit-transition: color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 24px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-input--selection-controls__input input {
  position: absolute;
  opacity: 0;
}
.v-input--selection-controls__input + .v-label {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.v-input--selection-controls__ripple {
  cursor: pointer;
  height: 48px;
  position: absolute;
  -webkit-transition: inherit;
  transition: inherit;
  width: 48px;
  left: -12px;
  top: calc(50% - 24px);
}
.v-input--selection-controls__ripple:before {
  border-radius: 50%;
  bottom: 0;
  content: '';
  position: absolute;
  opacity: 0.2;
  left: 0;
  right: 0;
  top: 0;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-transform: scale(0.2);
          transform: scale(0.2);
  -webkit-transition: inherit;
  transition: inherit;
}
.v-input--selection-controls.v-input .v-label {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  top: 0;
  height: auto;
}
.v-input--selection-controls.v-input--is-focused .v-input--selection-controls__ripple:before, .v-input--selection-controls .v-radio--is-focused .v-input--selection-controls__ripple:before {
  background: currentColor;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.theme--light .v-input--slider .v-slider__track, .application .theme--light.v-input--slider .v-slider__track, .theme--light .v-input--slider .v-slider__track-fill, .application .theme--light.v-input--slider .v-slider__track-fill {
  background: rgba(0,0,0,0.26);
}
.theme--light .v-input--slider .v-slider__track__container:after, .application .theme--light.v-input--slider .v-slider__track__container:after {
  border: 1px solid rgba(0,0,0,0.87);
}
.theme--light .v-input--slider .v-slider__ticks, .application .theme--light.v-input--slider .v-slider__ticks {
  border-color: rgba(0,0,0,0.87);
  color: rgba(0,0,0,0.54);
}
.theme--light .v-input--slider:not(.v-input--is-dirty) .v-slider__thumb-label, .application .theme--light.v-input--slider:not(.v-input--is-dirty) .v-slider__thumb-label {
  background: rgba(0,0,0,0.26);
}
.theme--light .v-input--slider:not(.v-input--is-dirty) .v-slider__thumb, .application .theme--light.v-input--slider:not(.v-input--is-dirty) .v-slider__thumb {
  border: 3px solid rgba(0,0,0,0.26);
}
.theme--light .v-input--slider:not(.v-input--is-dirty).v-input--slider--is-active .v-slider__thumb, .application .theme--light.v-input--slider:not(.v-input--is-dirty).v-input--slider--is-active .v-slider__thumb {
  border: 3px solid rgba(0,0,0,0.38);
}
.theme--light .v-input--slider.v-input--is-disabled .v-slider__thumb, .application .theme--light.v-input--slider.v-input--is-disabled .v-slider__thumb {
  border: 5px solid rgba(0,0,0,0.26);
}
.theme--light .v-input--slider.v-input--is-disabled.v-input--is-dirty .v-slider__thumb, .application .theme--light.v-input--slider.v-input--is-disabled.v-input--is-dirty .v-slider__thumb {
  background: rgba(0,0,0,0.26);
}
.theme--light .v-input--slider.v-input--slider--is-active .v-slider__track, .application .theme--light.v-input--slider.v-input--slider--is-active .v-slider__track {
  background: rgba(0,0,0,0.38);
}
.theme--dark .v-input--slider .v-slider__track, .application .theme--dark.v-input--slider .v-slider__track, .theme--dark .v-input--slider .v-slider__track-fill, .application .theme--dark.v-input--slider .v-slider__track-fill {
  background: rgba(255,255,255,0.2);
}
.theme--dark .v-input--slider .v-slider__track__container:after, .application .theme--dark.v-input--slider .v-slider__track__container:after {
  border: 1px solid #fff;
}
.theme--dark .v-input--slider .v-slider__ticks, .application .theme--dark.v-input--slider .v-slider__ticks {
  border-color: #fff;
  color: rgba(255,255,255,0.7);
}
.theme--dark .v-input--slider:not(.v-input--is-dirty) .v-slider__thumb-label, .application .theme--dark.v-input--slider:not(.v-input--is-dirty) .v-slider__thumb-label {
  background: rgba(255,255,255,0.2);
}
.theme--dark .v-input--slider:not(.v-input--is-dirty) .v-slider__thumb, .application .theme--dark.v-input--slider:not(.v-input--is-dirty) .v-slider__thumb {
  border: 3px solid rgba(255,255,255,0.2);
}
.theme--dark .v-input--slider:not(.v-input--is-dirty).v-input--slider--is-active .v-slider__thumb, .application .theme--dark.v-input--slider:not(.v-input--is-dirty).v-input--slider--is-active .v-slider__thumb {
  border: 3px solid rgba(255,255,255,0.3);
}
.theme--dark .v-input--slider.v-input--is-disabled .v-slider__thumb, .application .theme--dark.v-input--slider.v-input--is-disabled .v-slider__thumb {
  border: 5px solid rgba(255,255,255,0.2);
}
.theme--dark .v-input--slider.v-input--is-disabled.v-input--is-dirty .v-slider__thumb, .application .theme--dark.v-input--slider.v-input--is-disabled.v-input--is-dirty .v-slider__thumb {
  background: rgba(255,255,255,0.2);
}
.theme--dark .v-input--slider.v-input--slider--is-active .v-slider__track, .application .theme--dark.v-input--slider.v-input--slider--is-active .v-slider__track {
  background: rgba(255,255,255,0.3);
}
.application--is-rtl .v-input--slider .v-label {
  margin-left: 16px;
  margin-right: 0;
}
.v-input--slider:not(.v-input--slider--thumb-label) {
  margin-top: 0;
}
.v-input--slider.v-input--is-focused .v-slider__thumb-container--is-active:not(.v-slider__thumb-container--show-label):before {
  opacity: 0.2;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.v-input--slider.v-input--is-focused .v-slider__track {
  -webkit-transition: none;
  transition: none;
}
.v-input--slider.v-input--is-focused.v-input--slider--ticks .v-slider__track__container:after, .v-input--slider.v-input--is-focused.v-input--slider--ticks .v-slider .v-slider__tick {
  opacity: 1;
}
.v-input--slider.v-input--slider--ticks .v-slider__ticks.v-slider__ticks--always-show {
  opacity: 1;
}
.v-input--slider.v-input--slider--ticks-labels .v-input__slot {
  margin-bottom: 16px;
}
.v-input--slider.v-input--is-readonly {
  pointer-events: none;
}
.v-input--slider.v-input--is-disabled .v-slider__thumb {
  -webkit-transform: translateY(-50%) scale(0.45);
          transform: translateY(-50%) scale(0.45);
}
.v-input--slider.v-input--is-disabled.v-input--is-dirty .v-slider__thumb {
  border: 0px solid transparent;
}
.v-input--slider .v-input__slot > *:first-child:not(:only-child) {
  margin-right: 16px;
}
.v-slider {
  cursor: default;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  height: 32px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-slider input {
  cursor: default;
  opacity: 0;
  padding: 0;
  width: 100%;
}
.v-slider__track__container {
  height: 2px;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
}
.v-slider__track__container:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 2px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 2px;
  opacity: 0;
}
.v-slider__track, .v-slider__thumb, .v-slider__ticks {
  position: absolute;
  top: 0;
}
.v-slider__track {
  height: 2px;
  left: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  -webkit-transform-origin: right;
          transform-origin: right;
  overflow: hidden;
  width: 100%;
}
.v-slider__track-fill {
  position: absolute;
  left: 0;
  height: 2px;
  -webkit-transform-origin: left;
          transform-origin: left;
  width: 100%;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-slider__ticks-container {
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}
.v-slider__ticks {
  opacity: 0;
  border-style: solid;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-slider__ticks > span {
  position: absolute;
  top: 8px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-slider__ticks:first-child > span {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.v-slider__ticks:last-child > span {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.v-slider:not(.v-input--is-dirty) .v-slider__ticks:first-child {
  border-color: transparent;
}
.v-slider__thumb-container {
  position: absolute;
  top: 50%;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-slider__thumb-container:before {
  content: '';
  color: inherit;
  border-radius: 50%;
  background: currentColor;
  height: 32px;
  left: -16px;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: -16px;
  -webkit-transform: scale(0.2);
          transform: scale(0.2);
  -webkit-transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  width: 32px;
  will-change: transform, opacity;
}
.v-slider__thumb {
  width: 24px;
  height: 24px;
  left: -12px;
  top: 50%;
  border-radius: 50%;
  background: transparent;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  -webkit-transform: translateY(-50%) scale(0.6);
          transform: translateY(-50%) scale(0.6);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-slider--is-active .v-slider__thumb-container--is-active .v-slider__thumb {
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1);
}
.v-slider--is-active .v-slider__thumb-container--is-active.v-slider__thumb-container--show-label .v-slider__thumb {
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
}
.v-slider--is-active .v-slider__ticks-container .v-slider__ticks {
  opacity: 1;
}
.v-slider__thumb-label__container {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.v-slider__thumb-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 50% 50% 0;
  position: absolute;
  left: 0;
  bottom: 100%;
  -webkit-transform: translateY(-20%) translateY(-12px) translateX(-50%) rotate(45deg);
          transform: translateY(-20%) translateY(-12px) translateX(-50%) rotate(45deg);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.v-slider__thumb-label > * {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.v-slider__track, .v-slider__track-fill {
  position: absolute;
}
.theme--light .v-small-dialog__content, .application .theme--light.v-small-dialog__content {
  background: #fff;
}
.theme--light .v-small-dialog__actions, .application .theme--light.v-small-dialog__actions {
  background: #fff;
}
.theme--light .v-small-dialog a, .application .theme--light.v-small-dialog a {
  color: rgba(0,0,0,0.87);
}
.theme--dark .v-small-dialog__content, .application .theme--dark.v-small-dialog__content {
  background: #424242;
}
.theme--dark .v-small-dialog__actions, .application .theme--dark.v-small-dialog__actions {
  background: #424242;
}
.theme--dark .v-small-dialog a, .application .theme--dark.v-small-dialog a {
  color: #fff;
}
.v-small-dialog {
  display: block;
  width: 100%;
  height: 100%;
}
.v-small-dialog__content {
  padding: 0 24px;
}
.v-small-dialog__actions {
  text-align: right;
}
.v-small-dialog a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  text-decoration: none;
}
.v-small-dialog a > * {
  width: 100%;
}
.v-small-dialog .v-menu__activator {
  height: 100%;
}
.v-snack {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  pointer-events: none;
  z-index: 1000;
  font-size: 14px;
  left: 0;
  right: 0;
}
.v-snack--absolute {
  position: absolute;
}
.v-snack--top {
  top: 0;
}
.v-snack--bottom {
  bottom: 0;
}
.v-snack__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #323232;
  pointer-events: auto;
  width: 100%;
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
}
.v-snack__content {
  width: 100%;
  height: 48px;
  padding: 14px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  overflow: hidden;
}
.v-snack__content .v-btn {
  color: #fff;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 8px;
  margin: 0 0 0 24px;
  height: auto;
  min-width: auto;
  width: auto;
}
.v-snack__content .v-btn__content {
  margin: -2px;
}
.v-snack__content .v-btn::before {
  display: none;
}
.v-snack--multi-line .v-snack__content {
  height: 80px;
  padding: 24px;
}
.v-snack--vertical .v-snack__content {
  height: 112px;
  padding: 24px 24px 14px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.v-snack--vertical .v-snack__content .v-btn.v-btn {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0;
  margin-left: 0;
  margin-top: 24px;
}
.v-snack--vertical .v-snack__content .v-btn__content {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  margin: 0;
}
.v-snack--auto-height .v-snack__content {
  height: auto;
}
@media only screen and (min-width: 600px) {
  .v-snack__wrapper {
    width: auto;
    max-width: 568px;
    min-width: 288px;
    margin: 0 auto;
    border-radius: 2px;
  }
  .v-snack--left .v-snack__wrapper {
    margin-left: 0;
  }
  .v-snack--right .v-snack__wrapper {
    margin-right: 0;
  }
  .v-snack--left, .v-snack--right {
    margin: 0 24px;
  }
  .v-snack--left.v-snack--top, .v-snack--right.v-snack--top {
    -webkit-transform: translateY(24px);
            transform: translateY(24px);
  }
  .v-snack--left.v-snack--bottom, .v-snack--right.v-snack--bottom {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
  }
  .v-snack__content .v-btn:first-of-type {
    margin-left: 42px;
  }
}
.v-snack-transition-enter-active, .v-snack-transition-leave-active {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.5, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-snack-transition-enter-active .v-snack__content, .v-snack-transition-leave-active .v-snack__content {
  -webkit-transition: opacity 0.3s linear 0.1s;
  transition: opacity 0.3s linear 0.1s;
}
.v-snack-transition-enter .v-snack__content {
  opacity: 0;
}
.v-snack-transition-enter-to .v-snack__content, .v-snack-transition-leave .v-snack__content {
  opacity: 1;
}
.v-snack-transition-enter.v-snack.v-snack--top, .v-snack-transition-leave-to.v-snack.v-snack--top {
  -webkit-transform: translateY(calc(-100% - 8px));
          transform: translateY(calc(-100% - 8px));
}
.v-snack-transition-enter.v-snack.v-snack--bottom, .v-snack-transition-leave-to.v-snack.v-snack--bottom {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.v-speed-dial {
  position: relative;
}
.v-speed-dial--absolute {
  position: absolute;
}
.v-speed-dial--fixed {
  position: fixed;
}
.v-speed-dial--fixed, .v-speed-dial--absolute {
  z-index: 4;
}
.v-speed-dial--fixed > .v-btn--floating, .v-speed-dial--absolute > .v-btn--floating {
  margin: 0;
}
.v-speed-dial--top:not(.v-speed-dial--absolute) {
  top: 16px;
}
.v-speed-dial--top.v-speed-dial--absolute {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.v-speed-dial--bottom:not(.v-speed-dial--absolute) {
  bottom: 16px;
}
.v-speed-dial--bottom.v-speed-dial--absolute {
  bottom: 50%;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}
.v-speed-dial--left {
  left: 16px;
}
.v-speed-dial--right {
  right: 16px;
}
.v-speed-dial--direction-left .v-speed-dial__list, .v-speed-dial--direction-right .v-speed-dial__list {
  height: 100%;
  top: 0;
}
.v-speed-dial--direction-top .v-speed-dial__list, .v-speed-dial--direction-bottom .v-speed-dial__list {
  left: 0;
  width: 100%;
}
.v-speed-dial--direction-top .v-speed-dial__list {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  bottom: 100%;
}
.v-speed-dial--direction-right .v-speed-dial__list {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  left: 100%;
}
.v-speed-dial--direction-bottom .v-speed-dial__list {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  top: 100%;
}
.v-speed-dial--direction-left .v-speed-dial__list {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  right: 100%;
}
.v-speed-dial__list {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
}
.v-speed-dial__list .v-btn:nth-child(1) {
  -webkit-transition-delay: 0.05s;
          transition-delay: 0.05s;
}
.v-speed-dial__list .v-btn:nth-child(2) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.v-speed-dial__list .v-btn:nth-child(3) {
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}
.v-speed-dial__list .v-btn:nth-child(4) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.v-speed-dial__list .v-btn:nth-child(5) {
  -webkit-transition-delay: 0.25s;
          transition-delay: 0.25s;
}
.v-speed-dial__list .v-btn:nth-child(6) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.v-speed-dial__list .v-btn:nth-child(7) {
  -webkit-transition-delay: 0.35s;
          transition-delay: 0.35s;
}
.theme--light .v-stepper, .application .theme--light.v-stepper {
  background: #fff;
}
.theme--light .v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step, .application .theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: rgba(0,0,0,0.38);
}
.theme--light .v-stepper .v-stepper__step__step, .application .theme--light.v-stepper .v-stepper__step__step {
  color: #fff;
}
.theme--light .v-stepper .v-stepper__step__step .v-icon, .application .theme--light.v-stepper .v-stepper__step__step .v-icon {
  color: #fff;
}
.theme--light .v-stepper .v-stepper__header .v-divider, .application .theme--light.v-stepper .v-stepper__header .v-divider {
  border-color: rgba(0,0,0,0.12);
}
.theme--light .v-stepper .v-stepper__step--active .v-stepper__label, .application .theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
  text-shadow: 0px 0px 0px #000;
}
.theme--light .v-stepper .v-stepper__step--editable:hover, .application .theme--light.v-stepper .v-stepper__step--editable:hover {
  background: rgba(0,0,0,0.06);
}
.theme--light .v-stepper .v-stepper__step--editable:hover .v-stepper__label, .application .theme--light.v-stepper .v-stepper__step--editable:hover .v-stepper__label {
  text-shadow: 0px 0px 0px #000;
}
.theme--light .v-stepper .v-stepper__step--complete .v-stepper__label, .application .theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
  color: rgba(0,0,0,0.87);
}
.theme--light .v-stepper .v-stepper__step--inactive.v-stepper__step--editable:not(.v-stepper__step--error):hover .v-stepper__step__step, .application .theme--light.v-stepper .v-stepper__step--inactive.v-stepper__step--editable:not(.v-stepper__step--error):hover .v-stepper__step__step {
  background: rgba(0,0,0,0.54);
}
.theme--light .v-stepper .v-stepper__label, .application .theme--light.v-stepper .v-stepper__label {
  color: rgba(0,0,0,0.38);
}
.theme--light .v-stepper .v-stepper__label small, .application .theme--light.v-stepper .v-stepper__label small {
  color: rgba(0,0,0,0.54);
}
.theme--light .v-stepper--non-linear .v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__label, .application .theme--light.v-stepper--non-linear .v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__label {
  color: rgba(0,0,0,0.54);
}
.theme--light .v-stepper--vertical .v-stepper__content:not(:last-child), .application .theme--light.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-left: 1px solid rgba(0,0,0,0.12);
}
.theme--dark .v-stepper, .application .theme--dark.v-stepper {
  background: #303030;
}
.theme--dark .v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step, .application .theme--dark.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: rgba(255,255,255,0.5);
}
.theme--dark .v-stepper .v-stepper__step__step, .application .theme--dark.v-stepper .v-stepper__step__step {
  color: #fff;
}
.theme--dark .v-stepper .v-stepper__step__step .v-icon, .application .theme--dark.v-stepper .v-stepper__step__step .v-icon {
  color: #fff;
}
.theme--dark .v-stepper .v-stepper__header .v-divider, .application .theme--dark.v-stepper .v-stepper__header .v-divider {
  border-color: rgba(255,255,255,0.12);
}
.theme--dark .v-stepper .v-stepper__step--active .v-stepper__label, .application .theme--dark.v-stepper .v-stepper__step--active .v-stepper__label {
  text-shadow: 0px 0px 0px #fff;
}
.theme--dark .v-stepper .v-stepper__step--editable:hover, .application .theme--dark.v-stepper .v-stepper__step--editable:hover {
  background: rgba(255,255,255,0.06);
}
.theme--dark .v-stepper .v-stepper__step--editable:hover .v-stepper__label, .application .theme--dark.v-stepper .v-stepper__step--editable:hover .v-stepper__label {
  text-shadow: 0px 0px 0px #fff;
}
.theme--dark .v-stepper .v-stepper__step--complete .v-stepper__label, .application .theme--dark.v-stepper .v-stepper__step--complete .v-stepper__label {
  color: rgba(255,255,255,0.87);
}
.theme--dark .v-stepper .v-stepper__step--inactive.v-stepper__step--editable:not(.v-stepper__step--error):hover .v-stepper__step__step, .application .theme--dark.v-stepper .v-stepper__step--inactive.v-stepper__step--editable:not(.v-stepper__step--error):hover .v-stepper__step__step {
  background: rgba(255,255,255,0.75);
}
.theme--dark .v-stepper .v-stepper__label, .application .theme--dark.v-stepper .v-stepper__label {
  color: rgba(255,255,255,0.5);
}
.theme--dark .v-stepper .v-stepper__label small, .application .theme--dark.v-stepper .v-stepper__label small {
  color: rgba(255,255,255,0.7);
}
.theme--dark .v-stepper--non-linear .v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__label, .application .theme--dark.v-stepper--non-linear .v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__label {
  color: rgba(255,255,255,0.7);
}
.theme--dark .v-stepper--vertical .v-stepper__content:not(:last-child), .application .theme--dark.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-left: 1px solid rgba(255,255,255,0.12);
}
.v-stepper {
  overflow: hidden;
  position: relative;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.v-stepper__header {
  height: 72px;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.v-stepper__header .v-divider {
  -ms-flex-item-align: center;
      align-self: center;
  margin: 0 -16px;
}
.v-stepper__items {
  position: relative;
  overflow: hidden;
}
.v-stepper__step__step {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 12px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 24px;
  margin-right: 8px;
  min-width: 24px;
  width: 24px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.v-stepper__step__step .v-icon {
  font-size: 18px;
}
.v-stepper__step {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 24px;
  position: relative;
}
.v-stepper__step--active .v-stepper__label {
  -webkit-transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1);
  transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1);
}
.v-stepper__step--editable {
  cursor: pointer;
}
.v-stepper__step.v-stepper__step--error .v-stepper__step__step {
  background: transparent;
  color: inherit;
}
.v-stepper__step.v-stepper__step--error .v-stepper__step__step .v-icon {
  font-size: 24px;
  color: inherit;
}
.v-stepper__step.v-stepper__step--error .v-stepper__label {
  color: inherit;
  text-shadow: none;
  font-weight: 500;
}
.v-stepper__step.v-stepper__step--error .v-stepper__label small {
  color: inherit;
}
.v-stepper__label {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: left;
}
.v-stepper__label small {
  font-size: 12px;
  font-weight: 300;
  text-shadow: none;
}
.v-stepper__wrapper {
  overflow: hidden;
  -webkit-transition: none;
  transition: none;
}
.v-stepper__content {
  top: 0;
  padding: 24px 24px 16px 24px;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  width: 100%;
}
.v-stepper__content > .v-btn {
  margin: 24px 8px 8px 0;
}
.v-stepper--is-booted .v-stepper__content, .v-stepper--is-booted .v-stepper__wrapper {
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-stepper--vertical {
  padding-bottom: 36px;
}
.v-stepper--vertical .v-stepper__content {
  margin: -8px -36px -16px 36px;
  padding: 16px 60px 16px 23px;
  width: auto;
}
.v-stepper--vertical .v-stepper__step {
  padding: 24px 24px 16px;
}
.v-stepper--vertical .v-stepper__step__step {
  margin-right: 12px;
}
.v-stepper--alt-labels .v-stepper__header {
  height: auto;
}
.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 35px -67px 0;
  -ms-flex-item-align: start;
      align-self: flex-start;
}
.v-stepper--alt-labels .v-stepper__step {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-preferred-size: 175px;
      flex-basis: 175px;
}
.v-stepper--alt-labels .v-stepper__step small {
  -ms-flex-item-align: center;
      align-self: center;
}
.v-stepper--alt-labels .v-stepper__step__step {
  margin-right: 0;
  margin-bottom: 11px;
}
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: none;
  }
  .v-stepper:not(.v-stepper--vertical) .v-stepper__step__step {
    margin-right: 0;
  }
}
.theme--light .v-subheader, .application .theme--light.v-subheader {
  color: rgba(0,0,0,0.54);
}
.theme--dark .v-subheader, .application .theme--dark.v-subheader {
  color: rgba(255,255,255,0.7);
}
.v-subheader {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 16px 0 16px;
}
.v-subheader--inset {
  margin-left: 56px;
}
.theme--light .v-input--switch__thumb, .application .theme--light.v-input--switch__thumb {
  color: #fafafa;
}
.theme--light .v-input--switch__track, .application .theme--light.v-input--switch__track {
  color: rgba(0,0,0,0.38);
}
.theme--light .v-input--switch.v-input--is-disabled .v-input--switch__thumb, .application .theme--light.v-input--switch.v-input--is-disabled .v-input--switch__thumb {
  color: #bdbdbd !important;
}
.theme--light .v-input--switch.v-input--is-disabled .v-input--switch__track, .application .theme--light.v-input--switch.v-input--is-disabled .v-input--switch__track {
  color: rgba(0,0,0,0.12) !important;
}
.theme--dark .v-input--switch__thumb, .application .theme--dark.v-input--switch__thumb {
  color: #bdbdbd;
}
.theme--dark .v-input--switch__track, .application .theme--dark.v-input--switch__track {
  color: rgba(255,255,255,0.3);
}
.theme--dark .v-input--switch.v-input--is-disabled .v-input--switch__thumb, .application .theme--dark.v-input--switch.v-input--is-disabled .v-input--switch__thumb {
  color: #424242 !important;
}
.theme--dark .v-input--switch.v-input--is-disabled .v-input--switch__track, .application .theme--dark.v-input--switch.v-input--is-disabled .v-input--switch__track {
  color: rgba(255,255,255,0.1) !important;
}
.application--is-rtl .v-input--switch .v-input--selection-controls__ripple {
  left: auto;
  right: -14px;
}
.application--is-rtl .v-input--switch.v-input--is-dirty .v-input--selection-controls__ripple, .application--is-rtl .v-input--switch.v-input--is-dirty .v-input--switch__thumb {
  -webkit-transform: translate(-16px, 0);
          transform: translate(-16px, 0);
}
.v-input--switch__track, .v-input--switch__thumb {
  background-color: currentColor;
  pointer-events: none;
  -webkit-transition: inherit;
  transition: inherit;
}
.v-input--switch__track {
  border-radius: 8px;
  height: 14px;
  left: 2px;
  opacity: 0.6;
  position: absolute;
  right: 2px;
  top: calc(50% - 7px);
}
.v-input--switch__thumb {
  border-radius: 50%;
  top: calc(50% - 10px);
  height: 20px;
  position: relative;
  width: 20px;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
.v-input--switch .v-input--selection-controls__input {
  width: 38px;
}
.v-input--switch .v-input--selection-controls__ripple {
  left: -14px;
  top: calc(50% - 24px);
}
.v-input--switch.v-input--is-dirty .v-input--selection-controls__ripple, .v-input--switch.v-input--is-dirty .v-input--switch__thumb {
  -webkit-transform: translate(16px, 0);
          transform: translate(16px, 0);
}
.theme--light .v-system-bar, .application .theme--light.v-system-bar {
  background-color: #e0e0e0;
  color: rgba(0,0,0,0.54);
}
.theme--light .v-system-bar .v-icon, .application .theme--light.v-system-bar .v-icon {
  color: rgba(0,0,0,0.54);
}
.theme--light .v-system-bar--lights-out, .application .theme--light.v-system-bar--lights-out {
  background-color: rgba(255,255,255,0.7) !important;
}
.theme--dark .v-system-bar, .application .theme--dark.v-system-bar {
  background-color: #000;
  color: rgba(255,255,255,0.7);
}
.theme--dark .v-system-bar .v-icon, .application .theme--dark.v-system-bar .v-icon {
  color: rgba(255,255,255,0.7);
}
.theme--dark .v-system-bar--lights-out, .application .theme--dark.v-system-bar--lights-out {
  background-color: rgba(0,0,0,0.2) !important;
}
.v-system-bar {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  padding: 0 8px;
}
.v-system-bar .v-icon {
  font-size: 16px;
}
.v-system-bar--fixed, .v-system-bar--absolute {
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
}
.v-system-bar--fixed {
  position: fixed;
}
.v-system-bar--absolute {
  position: absolute;
}
.v-system-bar--status .v-icon {
  margin-right: 4px;
}
.v-system-bar--window .v-icon {
  font-size: 20px;
  margin-right: 8px;
}
.theme--light .v-table, .application .theme--light.v-table {
  background-color: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--light .v-table thead tr:first-child, .application .theme--light.v-table thead tr:first-child {
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
.theme--light .v-table thead th, .application .theme--light.v-table thead th {
  color: rgba(0,0,0,0.54);
}
.theme--light .v-table tbody tr:not(:last-child), .application .theme--light.v-table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
.theme--light .v-table tbody tr[active], .application .theme--light.v-table tbody tr[active] {
  background: #f5f5f5;
}
.theme--light .v-table tbody tr:hover:not(.v-datatable__expand-row), .application .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
  background: #eee;
}
.theme--light .v-table tfoot tr, .application .theme--light.v-table tfoot tr {
  border-top: 1px solid rgba(0,0,0,0.12);
}
.theme--dark .v-table, .application .theme--dark.v-table {
  background-color: #424242;
  color: #fff;
}
.theme--dark .v-table thead tr:first-child, .application .theme--dark.v-table thead tr:first-child {
  border-bottom: 1px solid rgba(255,255,255,0.12);
}
.theme--dark .v-table thead th, .application .theme--dark.v-table thead th {
  color: rgba(255,255,255,0.7);
}
.theme--dark .v-table tbody tr:not(:last-child), .application .theme--dark.v-table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(255,255,255,0.12);
}
.theme--dark .v-table tbody tr[active], .application .theme--dark.v-table tbody tr[active] {
  background: #505050;
}
.theme--dark .v-table tbody tr:hover:not(.v-datatable__expand-row), .application .theme--dark.v-table tbody tr:hover:not(.v-datatable__expand-row) {
  background: #616161;
}
.theme--dark .v-table tfoot tr, .application .theme--dark.v-table tfoot tr {
  border-top: 1px solid rgba(255,255,255,0.12);
}
.v-table__overflow {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
table.v-table {
  border-radius: 2px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
}
table.v-table thead td:not(:nth-child(1)), table.v-table tbody td:not(:nth-child(1)), table.v-table thead th:not(:nth-child(1)), table.v-table tbody th:not(:nth-child(1)), table.v-table thead td:first-child, table.v-table tbody td:first-child, table.v-table thead th:first-child, table.v-table tbody th:first-child {
  padding: 0 24px;
}
table.v-table thead tr {
  height: 56px;
}
table.v-table thead th {
  font-weight: 500;
  font-size: 12px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
table.v-table thead th.sortable {
  pointer-events: auto;
}
table.v-table thead th > div {
  width: 100%;
}
table.v-table tbody tr {
  -webkit-transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  will-change: background;
}
table.v-table tbody td, table.v-table tbody th {
  height: 48px;
}
table.v-table tbody td {
  font-weight: 400;
  font-size: 13px;
}
table.v-table .input-group--selection-controls {
  padding: 0;
}
table.v-table .input-group--selection-controls .input-group__details {
  display: none;
}
table.v-table .input-group--selection-controls.checkbox .v-icon {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
table.v-table .input-group--selection-controls.checkbox .input-group--selection-controls__ripple {
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
table.v-table tfoot tr {
  height: 48px;
}
table.v-table tfoot tr td {
  padding: 0 24px;
}
.theme--light .v-tabs__bar, .application .theme--light.v-tabs__bar {
  background-color: #fff;
}
.theme--light .v-tabs__bar .v-tabs__div, .application .theme--light.v-tabs__bar .v-tabs__div {
  color: rgba(0,0,0,0.87);
}
.theme--light .v-tabs__bar .v-tabs__item--disabled, .application .theme--light.v-tabs__bar .v-tabs__item--disabled {
  color: rgba(0,0,0,0.26);
}
.theme--dark .v-tabs__bar, .application .theme--dark.v-tabs__bar {
  background-color: #424242;
}
.theme--dark .v-tabs__bar .v-tabs__div, .application .theme--dark.v-tabs__bar .v-tabs__div {
  color: #fff;
}
.theme--dark .v-tabs__bar .v-tabs__item--disabled, .application .theme--dark.v-tabs__bar .v-tabs__item--disabled {
  color: rgba(255,255,255,0.3);
}
.v-tabs {
  position: relative;
}
.v-tabs__bar {
  position: relative;
}
.v-tabs__icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 32px;
}
.v-tabs__icon--prev {
  left: 4px;
}
.v-tabs__icon--next {
  right: 4px;
}
.v-tabs__wrapper {
  overflow: hidden;
  contain: content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.v-tabs__wrapper--show-arrows {
  margin-left: 40px;
  margin-right: 40px;
}
.v-tabs__wrapper--show-arrows .v-tabs__container--align-with-title {
  padding-left: 16px;
}
@media only screen and (max-width: 599px) {
  .v-tabs__wrapper--show-arrows .v-tabs__container--align-with-title {
    padding-left: 24px;
  }
}
.v-tabs__container {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  list-style-type: none;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.6s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
  white-space: nowrap;
  position: relative;
}
.v-tabs__container--overflow .v-tabs__div {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}
.v-tabs__container--grow .v-tabs__div {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  max-width: none;
}
.v-tabs__container--icons-and-text {
  height: 72px;
}
.v-tabs__container--align-with-title {
  padding-left: 56px;
}
.v-tabs__container--fixed-tabs .v-tabs__div, .v-tabs__container--icons-and-text .v-tabs__div {
  min-width: 72px;
}
@media only screen and (min-width: 600px) {
  .v-tabs__container--fixed-tabs .v-tabs__div, .v-tabs__container--icons-and-text .v-tabs__div {
    min-width: 160px;
  }
}
@media only screen and (max-width: 599px) {
  .v-tabs__container--fixed-tabs .v-tabs__div {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
  }
}
.v-tabs__container--centered > .v-tabs__div:first-child, .v-tabs__container--fixed-tabs > .v-tabs__div:first-child, .v-tabs__container--right > .v-tabs__div:first-child {
  margin-left: auto;
}
.v-tabs__container--centered .v-tabs__slider-wrapper + .v-tabs__div, .v-tabs__container--fixed-tabs .v-tabs__slider-wrapper + .v-tabs__div, .v-tabs__container--right .v-tabs__slider-wrapper + .v-tabs__div {
  margin-left: auto;
}
.v-tabs__container--centered > .v-tabs__div:last-child, .v-tabs__container--fixed-tabs > .v-tabs__div:last-child {
  margin-right: auto;
}
.v-tabs__container--icons-and-text .v-tabs__item {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.v-tabs__container--icons-and-text .v-tabs__item .v-icon {
  margin-bottom: 6px;
}
.v-tabs__div {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  height: inherit;
  max-width: 264px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
}
.v-tabs__item {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: inherit;
  padding: 6px 12px;
  text-decoration: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: normal;
}
.v-tabs__item:not(.v-tabs__item--active) {
  opacity: 0.7;
}
.v-tabs__item--disabled {
  pointer-events: none;
}
.v-tabs__slider {
  height: 2px;
  width: 100%;
}
.v-tabs__slider-wrapper {
  bottom: 0;
  margin: 0 !important;
  position: absolute;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-tabs__items {
  overflow: hidden;
  position: relative;
}
.v-tabs__content {
  width: 100%;
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}
.theme--light .v-text-field .v-input__slot:before, .application .theme--light.v-text-field .v-input__slot:before {
  border-color: rgba(0,0,0,0.42);
}
.theme--light .v-text-field:not(.v-input--has-state) .v-input__slot:hover:before, .application .theme--light.v-text-field:not(.v-input--has-state) .v-input__slot:hover:before {
  border-color: rgba(0,0,0,0.87);
}
.theme--light .v-text-field.v-input--is-disabled .v-input__slot:before, .application .theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  -o-border-image: repeating-linear-gradient(to right, rgba(0,0,0,0.38) 0px, rgba(0,0,0,0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
     border-image: repeating-linear-gradient(to right, rgba(0,0,0,0.38) 0px, rgba(0,0,0,0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
}
.theme--light .v-text-field__prefix, .application .theme--light.v-text-field__prefix, .theme--light .v-text-field__suffix, .application .theme--light.v-text-field__suffix {
  color: rgba(0,0,0,0.54);
}
.theme--light .v-text-field--solo .v-input__slot, .application .theme--light.v-text-field--solo .v-input__slot {
  border-radius: 2px;
  background: #fff;
}
.theme--light .v-text-field--solo-inverted.v-text-field--solo .v-input__slot, .application .theme--light.v-text-field--solo-inverted.v-text-field--solo .v-input__slot {
  background: rgba(0,0,0,0.16);
}
.theme--light .v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot, .application .theme--light.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot {
  background: #424242;
}
.theme--light .v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot .v-label, .application .theme--light.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot .v-label, .theme--light .v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot input, .application .theme--light.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot input {
  color: #fff;
}
.theme--light .v-text-field--box .v-input__slot, .application .theme--light.v-text-field--box .v-input__slot {
  background: rgba(0,0,0,0.06);
}
.theme--light .v-text-field--box:not(.v-input--is-focused) .v-input__slot:hover, .application .theme--light.v-text-field--box:not(.v-input--is-focused) .v-input__slot:hover {
  background: rgba(0,0,0,0.12);
}
.theme--light .v-text-field--outline .v-input__slot, .application .theme--light.v-text-field--outline .v-input__slot {
  border: 2px solid rgba(0,0,0,0.54);
}
.theme--light .v-text-field--outline:not(.v-input--is-focused) .v-input__slot:hover, .application .theme--light.v-text-field--outline:not(.v-input--is-focused) .v-input__slot:hover {
  border: 2px solid rgba(0,0,0,0.87);
}
.theme--dark .v-text-field .v-input__slot:before, .application .theme--dark.v-text-field .v-input__slot:before {
  border-color: rgba(255,255,255,0.7);
}
.theme--dark .v-text-field:not(.v-input--has-state) .v-input__slot:hover:before, .application .theme--dark.v-text-field:not(.v-input--has-state) .v-input__slot:hover:before {
  border-color: #fff;
}
.theme--dark .v-text-field.v-input--is-disabled .v-input__slot:before, .application .theme--dark.v-text-field.v-input--is-disabled .v-input__slot:before {
  -o-border-image: repeating-linear-gradient(to right, rgba(255,255,255,0.5) 0px, rgba(255,255,255,0.5) 2px, transparent 2px, transparent 4px) 1 repeat;
     border-image: repeating-linear-gradient(to right, rgba(255,255,255,0.5) 0px, rgba(255,255,255,0.5) 2px, transparent 2px, transparent 4px) 1 repeat;
}
.theme--dark .v-text-field__prefix, .application .theme--dark.v-text-field__prefix, .theme--dark .v-text-field__suffix, .application .theme--dark.v-text-field__suffix {
  color: rgba(255,255,255,0.7);
}
.theme--dark .v-text-field--solo .v-input__slot, .application .theme--dark.v-text-field--solo .v-input__slot {
  border-radius: 2px;
  background: #424242;
}
.theme--dark .v-text-field--solo-inverted.v-text-field--solo .v-input__slot, .application .theme--dark.v-text-field--solo-inverted.v-text-field--solo .v-input__slot {
  background: rgba(255,255,255,0.16);
}
.theme--dark .v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot, .application .theme--dark.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot {
  background: #fff;
}
.theme--dark .v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot .v-label, .application .theme--dark.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot .v-label, .theme--dark .v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot input, .application .theme--dark.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot input {
  color: rgba(0,0,0,0.87);
}
.theme--dark .v-text-field--box .v-input__slot, .application .theme--dark.v-text-field--box .v-input__slot {
  background: rgba(0,0,0,0.1);
}
.theme--dark .v-text-field--box:not(.v-input--is-focused) .v-input__slot:hover, .application .theme--dark.v-text-field--box:not(.v-input--is-focused) .v-input__slot:hover {
  background: rgba(0,0,0,0.2);
}
.theme--dark .v-text-field--outline .v-input__slot, .application .theme--dark.v-text-field--outline .v-input__slot {
  border: 2px solid rgba(255,255,255,0.7);
}
.theme--dark .v-text-field--outline:not(.v-input--is-focused) .v-input__slot:hover, .application .theme--dark.v-text-field--outline:not(.v-input--is-focused) .v-input__slot:hover {
  border: 2px solid #fff;
}
.application--is-rtl .v-text-field .v-label {
  -webkit-transform-origin: top right;
          transform-origin: top right;
}
.application--is-rtl .v-text-field .v-counter {
  margin-left: 0;
  margin-right: 8px;
}
.application--is-rtl .v-text-field--enclosed .v-input__append-outer {
  margin-left: 0;
  margin-right: 16px;
}
.application--is-rtl .v-text-field--enclosed .v-input__prepend-outer {
  margin-left: 16px;
  margin-right: 0;
}
.application--is-rtl .v-text-field--reverse input {
  text-align: left;
}
.application--is-rtl .v-text-field--reverse .v-label {
  -webkit-transform-origin: top left;
          transform-origin: top left;
}
.application--is-rtl .v-text-field__prefix {
  text-align: left;
  padding-right: 0;
  padding-left: 4px;
}
.application--is-rtl .v-text-field__suffix {
  padding-left: 0;
  padding-right: 4px;
}
.application--is-rtl .v-text-field--reverse .v-text-field__prefix {
  text-align: right;
  padding-left: 0;
  padding-right: 4px;
}
.application--is-rtl .v-text-field--reverse .v-text-field__suffix {
  padding-left: 0;
  padding-right: 4px;
}
.v-text-field input {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  width: 100%;
}
.v-text-field .v-input__prepend-inner, .v-text-field .v-input__append-inner {
  -ms-flex-item-align: start;
      align-self: flex-start;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-top: 4px;
  line-height: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-text-field .v-input__prepend-inner {
  margin-right: auto;
  padding-right: 4px;
}
.v-text-field .v-input__append-inner {
  margin-left: auto;
  padding-left: 4px;
}
.v-text-field .v-counter {
  margin-left: 8px;
  white-space: nowrap;
}
.v-text-field .v-label {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 6px;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  white-space: nowrap;
  pointer-events: none;
}
.v-text-field .v-label--active {
  max-width: 100%;
  -webkit-transform: translateY(-18px) scale(0.75);
          transform: translateY(-18px) scale(0.75);
}
.v-text-field .v-input__slot {
  -webkit-transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-text-field .v-input__slot:before, .v-text-field .v-input__slot:after {
  bottom: -1px;
  content: '';
  left: 0;
  position: absolute;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-text-field .v-input__slot:before {
  border-style: solid;
  border-width: thin 0 0 0;
}
.v-text-field .v-input__slot:after {
  border-color: currentColor;
  border-style: solid;
  border-width: thin 0 thin 0;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}
.v-text-field__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  max-width: 100%;
  overflow: hidden;
}
.v-text-field__prefix, .v-text-field__suffix {
  -ms-flex-item-align: center;
      align-self: center;
  cursor: default;
}
.v-text-field__prefix {
  width: 16px;
  text-align: right;
  padding-right: 4px;
}
.v-text-field__suffix {
  padding-left: 4px;
  white-space: nowrap;
}
.v-text-field--reverse .v-text-field__prefix {
  text-align: left;
  padding-right: 0;
  padding-left: 4px;
}
.v-text-field--reverse .v-text-field__suffix {
  padding-left: 0;
  padding-right: 4px;
}
.v-text-field__slot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  height: 100%;
  position: relative;
}
.v-text-field--box, .v-text-field--outline {
  position: relative;
}
.v-text-field--box .v-input__slot, .v-text-field--outline .v-input__slot {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.v-text-field--box input, .v-text-field--outline input {
  margin-top: 22px;
}
.v-text-field--box.v-text-field--single-line input, .v-text-field--outline.v-text-field--single-line input {
  margin-top: 12px;
}
.v-text-field--box .v-label, .v-text-field--outline .v-label {
  top: 18px;
}
.v-text-field--box .v-label--active, .v-text-field--outline .v-label--active {
  -webkit-transform: translateY(-6px) scale(0.75);
          transform: translateY(-6px) scale(0.75);
}
.v-text-field--box .v-input__slot, .v-text-field--outline .v-input__slot {
  min-height: 56px;
}
.v-text-field--box .v-input__slot {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.v-text-field--box .v-input__slot:before {
  border-style: solid;
  border-width: thin 0 thin 0;
}
.v-text-field.v-text-field--enclosed {
  margin: 0;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--box) .v-progress-linear__background {
  display: none;
}
.v-text-field.v-text-field--enclosed .v-input__prepend-outer, .v-text-field.v-text-field--enclosed .v-input__prepend-inner, .v-text-field.v-text-field--enclosed .v-input__append-inner, .v-text-field.v-text-field--enclosed .v-input__append-outer {
  margin-top: 16px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details, .v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0 12px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 8px;
}
.v-text-field.v-text-field--full-width.v-input {
  margin-bottom: 0;
  margin-top: 0;
}
.v-text-field.v-text-field--full-width .v-label {
  top: calc(50% - 10px);
}
.v-text-field.v-text-field--full-width .v-input__control {
  padding: 12px 0;
}
.v-text-field.v-text-field--full-width .v-input__prepend-outer, .v-text-field.v-text-field--full-width .v-input__append-outer {
  margin-top: 4px;
}
.v-text-field.v-text-field--full-width .v-input__append-inner {
  -ms-flex-item-align: center;
      align-self: center;
  margin-top: 0;
}
.v-text-field--reverse input {
  text-align: right;
}
.v-text-field--reverse .v-label {
  -webkit-transform-origin: top right;
          transform-origin: top right;
}
.v-text-field--reverse .v-input__slot, .v-text-field--reverse .v-text-field__slot {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.v-text-field--solo .v-input__slot:before, .v-text-field--outline .v-input__slot:before, .v-text-field--full-width .v-input__slot:before, .v-text-field--solo .v-input__slot:after, .v-text-field--outline .v-input__slot:after, .v-text-field--full-width .v-input__slot:after {
  display: none;
}
.v-text-field--outline {
  margin-bottom: 16px;
  -webkit-transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-text-field--outline .v-input__slot {
  background: transparent !important;
  border-radius: 4px;
}
.v-text-field--outline .v-input__prepend-outer, .v-text-field--outline .v-input__append-outer {
  margin-top: 18px;
}
.v-text-field--outline.v-input--is-focused .v-input__slot {
  border: 2px solid currentColor;
  -webkit-transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-text-field.v-text-field--solo .v-label {
  top: calc(50% - 10px);
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 48px;
  padding: 0;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) .v-input__slot {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.v-text-field.v-text-field--solo .v-text-field__slot {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.v-text-field.v-text-field--solo .v-input__append-inner, .v-text-field.v-text-field--solo .v-input__prepend-inner {
  -ms-flex-item-align: center;
      align-self: center;
  margin-top: 0;
}
.v-text-field.v-text-field--solo .v-input__prepend-outer, .v-text-field.v-text-field--solo .v-input__append-outer {
  margin-top: 12px;
}
.v-text-field.v-input--is-focused .v-input__slot:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.v-text-field.v-input--has-state .v-input__slot:before {
  border-color: currentColor;
}
.theme--light .v-textarea.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused textarea, .application .theme--light.v-textarea.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused textarea {
  color: #fff;
}
.theme--dark .v-textarea.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused textarea, .application .theme--dark.v-textarea.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused textarea {
  color: rgba(0,0,0,0.87);
}
.application--is-rtl .v-textarea.v-text-field--enclosed .v-text-field__slot {
  margin-right: 0;
  margin-left: -12px;
}
.application--is-rtl .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
  padding-right: 0;
  padding-left: 12px;
}
.v-textarea textarea {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  line-height: 18px;
  max-width: 100%;
  min-height: 32px;
  outline: none;
  padding: 7px 0 8px;
  width: 100%;
}
.v-textarea .v-text-field__prefix {
  padding-top: 4px;
  -ms-flex-item-align: start;
      align-self: start;
}
.v-textarea.v-text-field--full-width .v-text-field__slot textarea, .v-textarea.v-text-field--full-width.v-text-field--single-line .v-text-field__slot textarea {
  margin-top: 0;
}
.v-textarea.v-text-field--full-width .v-text-field__details, .v-textarea.v-text-field--full-width.v-text-field--single-line .v-text-field__details {
  bottom: 4px;
}
.v-textarea.v-text-field--enclosed .v-text-field__slot {
  margin-right: -12px;
}
.v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
  padding-right: 12px;
}
.v-textarea.v-text-field--box .v-text-field__prefix, .v-textarea.v-text-field--enclosed .v-text-field__prefix, .v-textarea.v-text-field--box textarea, .v-textarea.v-text-field--enclosed textarea {
  margin-top: 24px;
}
.v-textarea.v-text-field--box.v-text-field--single-line .v-text-field__prefix, .v-textarea.v-text-field--enclosed.v-text-field--single-line .v-text-field__prefix, .v-textarea.v-text-field--box.v-text-field--single-line textarea, .v-textarea.v-text-field--enclosed.v-text-field--single-line textarea {
  margin-top: 12px;
}
.v-textarea.v-text-field--box.v-text-field--single-line .v-label, .v-textarea.v-text-field--enclosed.v-text-field--single-line .v-label {
  top: 18px;
}
.v-textarea.v-text-field--box.v-text-field--single-line.v-text-field--outline .v-input__control, .v-textarea.v-text-field--enclosed.v-text-field--single-line.v-text-field--outline .v-input__control {
  padding-top: 0;
}
.v-textarea.v-text-field--solo {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.v-textarea.v-text-field--solo .v-input__prepend-inner, .v-textarea.v-text-field--solo .v-input__prepend-outer, .v-textarea.v-text-field--solo .v-input__append-inner, .v-textarea.v-text-field--solo .v-input__append-outer {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-top: 16px;
}
.v-textarea--auto-grow textarea {
  overflow: hidden;
}
.v-textarea--no-resize textarea {
  resize: none;
}
.theme--light .v-time-picker-clock, .application .theme--light.v-time-picker-clock {
  background: #e0e0e0;
}
.theme--light .v-time-picker-clock > span.disabled, .application .theme--light.v-time-picker-clock > span.disabled {
  color: rgba(0,0,0,0.26);
}
.theme--light .v-time-picker-clock > span.disabled.active, .application .theme--light.v-time-picker-clock > span.disabled.active {
  color: rgba(255,255,255,0.3);
}
.theme--light .v-time-picker-clock--indeterminate .v-time-picker-clock__hand, .application .theme--light.v-time-picker-clock--indeterminate .v-time-picker-clock__hand {
  background-color: #bdbdbd;
}
.theme--light .v-time-picker-clock--indeterminate .v-time-picker-clock__hand:after, .application .theme--light.v-time-picker-clock--indeterminate .v-time-picker-clock__hand:after {
  color: #bdbdbd;
}
.theme--light .v-time-picker-clock--indeterminate > span.active, .application .theme--light.v-time-picker-clock--indeterminate > span.active {
  background-color: #bdbdbd;
}
.theme--dark .v-time-picker-clock, .application .theme--dark.v-time-picker-clock {
  background: #616161;
}
.theme--dark .v-time-picker-clock > span.disabled, .application .theme--dark.v-time-picker-clock > span.disabled {
  color: rgba(255,255,255,0.3);
}
.theme--dark .v-time-picker-clock > span.disabled.active, .application .theme--dark.v-time-picker-clock > span.disabled.active {
  color: rgba(255,255,255,0.3);
}
.theme--dark .v-time-picker-clock--indeterminate .v-time-picker-clock__hand, .application .theme--dark.v-time-picker-clock--indeterminate .v-time-picker-clock__hand {
  background-color: #757575;
}
.theme--dark .v-time-picker-clock--indeterminate .v-time-picker-clock__hand:after, .application .theme--dark.v-time-picker-clock--indeterminate .v-time-picker-clock__hand:after {
  color: #757575;
}
.theme--dark .v-time-picker-clock--indeterminate > span.active, .application .theme--dark.v-time-picker-clock--indeterminate > span.active {
  background-color: #757575;
}
.v-time-picker-clock {
  border-radius: 100%;
  position: relative;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-time-picker-clock__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px;
}
.v-time-picker-clock__hand {
  height: calc(50% - 28px);
  width: 2px;
  bottom: 50%;
  left: calc(50% - 1px);
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  position: absolute;
  will-change: transform;
  z-index: 1;
}
.v-time-picker-clock__hand:before {
  background: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: inherit;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  content: '';
  position: absolute;
  top: -3%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.v-time-picker-clock__hand:after {
  content: '';
  position: absolute;
  height: 8px;
  width: 8px;
  top: 100%;
  left: 50%;
  border-radius: 100%;
  border-style: solid;
  border-color: inherit;
  background-color: inherit;
  -webkit-transform: translate(calc(-50%), -50%);
          transform: translate(calc(-50%), -50%);
}
.v-time-picker-clock > span {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 100%;
  cursor: default;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: calc(50% - 40px / 2);
  height: 40px;
  position: absolute;
  text-align: center;
  top: calc(50% - 40px / 2);
  width: 40px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-time-picker-clock > span > span {
  z-index: 1;
}
.v-time-picker-clock > span:before, .v-time-picker-clock > span:after {
  content: '';
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 14px;
  width: 14px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.v-time-picker-clock > span:after, .v-time-picker-clock > span:before {
  height: 40px;
  width: 40px;
}
.v-time-picker-clock > span.active {
  color: #fff;
  cursor: default;
  z-index: 2;
}
.v-time-picker-clock > span.disabled {
  pointer-events: none;
}
.v-time-picker-title {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.v-time-picker-title__time {
  white-space: nowrap;
}
.v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 70px;
  font-size: 70px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.v-time-picker-title__ampm {
  -ms-flex-item-align: end;
      align-self: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 16px;
  margin: 8px 0 6px 8px;
  text-transform: uppercase;
}
.v-time-picker-title__ampm div:only-child {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.v-picker__title--landscape .v-time-picker-title {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}
.v-picker__title--landscape .v-time-picker-title__time {
  text-align: right;
}
.v-picker__title--landscape .v-time-picker-title__time .v-picker__title__btn, .v-picker__title--landscape .v-time-picker-title__time span {
  height: 55px;
  font-size: 55px;
}
.v-picker__title--landscape .v-time-picker-title__ampm {
  margin: 16px 0 0;
  -ms-flex-item-align: initial;
      align-self: initial;
  text-align: center;
}
.theme--light .v-toolbar, .application .theme--light.v-toolbar {
  background-color: #f5f5f5;
  color: rgba(0,0,0,0.87);
}
.theme--dark .v-toolbar, .application .theme--dark.v-toolbar {
  background-color: #212121;
  color: #fff;
}
.v-toolbar {
  -webkit-transition: none;
  transition: none;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  position: relative;
  width: 100%;
  will-change: padding-left, padding-right;
}
.v-toolbar[data-booted="true"] {
  -webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.v-toolbar .v-text-field--enclosed, .v-toolbar .v-text-field--box {
  margin: 0;
}
.v-toolbar .v-text-field--enclosed .v-text-field__details, .v-toolbar .v-text-field--box .v-text-field__details {
  display: none;
}
.v-toolbar .v-tabs {
  width: 100%;
}
.v-toolbar__title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.02em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-toolbar__title:not(:first-child) {
  margin-left: 20px;
}
.v-toolbar__content, .v-toolbar__extension {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 24px;
}
@media only screen and (max-width: 959px) {
  .v-toolbar__content, .v-toolbar__extension {
    padding: 0 16px;
  }
}
.v-toolbar__content .v-btn--icon, .v-toolbar__extension .v-btn--icon {
  margin: 6px;
}
.v-toolbar__content > *:first-child, .v-toolbar__extension > *:first-child {
  margin-left: 0;
}
.v-toolbar__content > *:first-child.v-btn--icon, .v-toolbar__extension > *:first-child.v-btn--icon {
  margin-left: -6px;
}
.v-toolbar__content > *:first-child.v-menu .v-menu__activator .v-btn, .v-toolbar__extension > *:first-child.v-menu .v-menu__activator .v-btn, .v-toolbar__content > *:first-child.v-tooltip span .v-btn, .v-toolbar__extension > *:first-child.v-tooltip span .v-btn {
  margin-left: 0;
}
.v-toolbar__content > *:first-child.v-menu .v-menu__activator .v-btn--icon, .v-toolbar__extension > *:first-child.v-menu .v-menu__activator .v-btn--icon, .v-toolbar__content > *:first-child.v-tooltip span .v-btn--icon, .v-toolbar__extension > *:first-child.v-tooltip span .v-btn--icon {
  margin-left: -6px;
}
.v-toolbar__content > *:last-child, .v-toolbar__extension > *:last-child {
  margin-right: 0;
}
.v-toolbar__content > *:last-child.v-btn--icon, .v-toolbar__extension > *:last-child.v-btn--icon {
  margin-right: -6px;
}
.v-toolbar__content > *:last-child.v-menu .v-menu__activator .v-btn, .v-toolbar__extension > *:last-child.v-menu .v-menu__activator .v-btn, .v-toolbar__content > *:last-child.v-tooltip span .v-btn, .v-toolbar__extension > *:last-child.v-tooltip span .v-btn {
  margin-right: 0;
}
.v-toolbar__content > *:last-child.v-menu .v-menu__activator .v-btn--icon, .v-toolbar__extension > *:last-child.v-menu .v-menu__activator .v-btn--icon, .v-toolbar__content > *:last-child.v-tooltip span .v-btn--icon, .v-toolbar__extension > *:last-child.v-tooltip span .v-btn--icon {
  margin-right: -6px;
}
.v-toolbar__content > .v-list, .v-toolbar__extension > .v-list {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  max-height: 100%;
}
.v-toolbar__content > .v-list:first-child, .v-toolbar__extension > .v-list:first-child {
  margin-left: -24px;
}
@media only screen and (max-width: 959px) {
  .v-toolbar__content > .v-list:first-child, .v-toolbar__extension > .v-list:first-child {
    margin-left: -16px;
  }
}
.v-toolbar__content > .v-list:last-child, .v-toolbar__extension > .v-list:last-child {
  margin-right: -24px;
}
@media only screen and (max-width: 959px) {
  .v-toolbar__content > .v-list:last-child, .v-toolbar__extension > .v-list:last-child {
    margin-right: -16px;
  }
}
.v-toolbar__extension > .v-toolbar__title {
  margin-left: 72px;
}
.v-toolbar__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: inherit;
  max-width: 100%;
  padding: 0;
}
.v-toolbar__items .v-btn {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.v-toolbar__items .v-tooltip, .v-toolbar__items .v-tooltip > span {
  height: inherit;
}
.v-toolbar__items .v-btn, .v-toolbar__items .v-menu, .v-toolbar__items .v-menu__activator {
  height: inherit;
  margin: 0;
}
.v-toolbar .v-overflow-btn, .v-toolbar .v-btn-toggle {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
}
.v-toolbar .v-input {
  margin: 0;
}
.v-toolbar .v-overflow-btn .v-input__control:before, .v-toolbar .v-overflow-btn .v-input__slot:before {
  display: none;
}
.v-toolbar--card {
  border-radius: 2px 2px 0 0;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
}
.v-toolbar--fixed {
  position: fixed;
  z-index: 2;
}
.v-toolbar--fixed, .v-toolbar--absolute {
  top: 0;
  left: 0;
}
.v-toolbar--absolute {
  position: absolute;
  z-index: 2;
}
.v-toolbar--floating {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 16px;
  width: auto;
}
.v-toolbar--clipped {
  z-index: 3;
}
.v-tooltip {
  position: relative;
}
.v-tooltip__content {
  background: #616161;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  padding: 5px 8px;
  position: absolute;
  text-transform: initial;
  -webkit-transition: 0.15s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.15s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: auto;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
          box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.v-tooltip__content[class*="-active"] {
  pointer-events: none;
}
@media only screen and (max-width: 959px) {
  .v-tooltip .v-tooltip__content {
    padding: 10px 16px;
  }
}
a {
  color: #1976d2;
  text-decoration: none;
}
a:hover, a:focus {
  color: #424242;
}
/*# sourceMappingURL=frontend/src/assets/styles/bundles/static.css.map */